import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Block, Box } from '../../../styles/BasicStyles';
import { BorderColor, FocusColor, LabelColor, PlaceholderColor } from '../../../styles/Colors';
import { showSuccess } from '../../../hooks/show-notification/show-notification';
import { useDidUpdate } from '@mantine/hooks';
import { AnyObject } from '../../../components/inputs/Generic';
import { PaginationObject } from '../../../components/page/DefaultListPage';
import { Federation, FederationCustomField } from '../../../models/Federation';
import { CustomField } from '../../../models/CustomField';
import { OFFICIAL, ATHLETE, COACH, RESPONSIBLE, PROMOTER, CLUB } from '../../../models/User';
import { Tab } from './styles';
import { Grid } from '@mantine/core';
import { UserContext } from '../../../Context';
import useFetch from 'use-http';
import Typography from '../../../components/typography';
import BaseTable from '../../../components/table';
import ConstructPaginationRequest from '../../../utils/requests/ConstructPaginationRequest';
import Button from '../../../components/button';
import SelectInput from '../../../components/inputs/SelectInput';
import SwitchInput from '../../../components/inputs/SwitchInput';

const CustomFieldsTable = BaseTable<CustomField>();

const CustomFieldsPage = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [federations, setFederations] = useState<Federation[]>([]);
  const [selectedFederation, setSelectedFederation] = useState<Federation>();
  const [fieldsToggle, setFieldsToggle] = useState<FederationCustomField[]>([]);
  const [type, setType] = useState<string>(OFFICIAL);
  const [pagination, setPagination] = useState<PaginationObject>({
    page: 1,
    limit: 20
  });
  const [rows, setRows] = useState<CustomField[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { get } = useFetch('/custom-fields');
  const federationsHook = useFetch('/federations');
  const { user } = useContext(UserContext);

  useEffect(() => {
    const init = async () => {
      const defaultPagination: PaginationObject = {
        page: 1,
        limit: 20
      };

      setPagination(defaultPagination);

      if(user?.type === RESPONSIBLE) {
        setSelectedFederation(user.federation);
        setFieldsToggle(user.federation?.custom_fields || []);
      }
      else {
        const federationsResult = await federationsHook.get('/dropdown');

        setFederations(federationsResult?.data || []);
        setSelectedFederation(federationsResult?.data[0]);
        setFieldsToggle(federationsResult?.data[0]?.custom_fields || []);
      }
    };

    init();
  }, []);

  useDidUpdate(() => {
    const init = async () => {
      setLoading(true);

      const url = ConstructPaginationRequest(
        `/${pagination.page}/${pagination.limit}`,
        { type, federation: selectedFederation?._id }
      );

      const result = await get(url);

      if(pagination.page > 1 && (result?.data?.items || []).length === 0) {
        const pag = { ...pagination, page: 1 };
        handleNewPagination(pag);
      } 
      else {
        setRows(result?.data?.items || []);
        setTotal(result?.data?.total || 0);
        setLoading(false);
      }
    };
    
    if(type && selectedFederation?._id) init();
  }, [pagination, type, selectedFederation?._id]);

  const handleNewPagination = (pag: PaginationObject) => {
    setPagination(pag);
  };

  const handleNewPage = (page: number) => {
    const pag = { ...pagination, page };
    handleNewPagination(pag);
  };

  const onSubmit = async () => {
    setSubmitting(true);

    if(selectedFederation?._id) {
      const { success } = await federationsHook.put(`/${selectedFederation._id}/custom-fields`, { custom_fields: fieldsToggle });

      if(success) {
        showSuccess({
          title: t('SUCCESS'),
          message: t('CUSTOM_FIELDS_SUCCESS')
        });
      }
    }
    
    setSubmitting(false);
  };

  const toggleActiveField = (field: string | undefined, value: boolean) => {
    if(field) {
      const aux = [...fieldsToggle];
      const index = aux.findIndex(elem => elem.field === field);
      if(index >= 0) {
        aux[index].active = value;
        aux[index].required = !value ? false : aux[index].required;
      }
      else aux.push({ field, active: value, required: false });

      setFieldsToggle([...aux]);
    }
  };

  const toggleRequiredField = (field: string | undefined, value: boolean) => {
    if(field) {
      const aux = [...fieldsToggle];
      const index = aux.findIndex(elem => elem.field === field);
      if(index >= 0) {
        aux[index].required = value;
        aux[index].active = value ? true : aux[index].active;
      }
      else aux.push({ field, active: true, required: value });

      setFieldsToggle([...aux]);
    }
  };

  const changeFederation = (value: string | null) => {
    const newFederation = federations.find((elem: Federation) => elem._id === value);

    if(newFederation) {
      setSelectedFederation(newFederation);
      setFieldsToggle(newFederation.custom_fields || []);
    }
  };

  const getFederationLink = () => {
    if(!selectedFederation) return '';
    return `${process.env.REACT_APP_WEBAPP_URL}/external/${type.toLowerCase()}/${selectedFederation.code}`;
  };

  const copyFederationLink = () => {
    navigator.clipboard.writeText(getFederationLink());
    showSuccess({
      title: t('SUCCESS'),
      message: t('LINK_COPIED')
    });
  };

  const columns = [
    { 
      title: t('FIELD_NAME'), 
      dataIndex: 'name',
      width: '70%',
      render: (value: AnyObject) => <Typography variant='table-header'>{t(value)}</Typography>
    },
    {
      title: t('FIELD_STATUS'),
      dataIndex: 'required',
      render: (value: boolean, field: CustomField) => (
        value ?
        <Typography variant='table-header' style={{ color: PlaceholderColor }}>{t('MANDATORY')}</Typography>
        :
        <SwitchInput 
          input={{
            value: fieldsToggle?.find(elem => elem.field === field._id)?.active || false,
            onChange: (v: boolean) => toggleActiveField(field?._id, v)
          }}
        />
      )
    },
    {
      title: t('FIELD_REQUIRED'),
      dataIndex: 'required',
      render: (value: boolean, field: CustomField) => (
        !value &&
        <SwitchInput 
          input={{
            value: fieldsToggle?.find(elem => elem.field === field._id)?.required || false,
            onChange: (v: boolean) => toggleRequiredField(field?._id, v)
          }}
        />
      )
    }
  ];
  
  return (
    <Block maxW={45.938} margin='auto' mt={3}>
      <Box fAlign='center' fWrap='wrap' mb={1}>
        <Block w={{ xs: '50%', xxs: '100%' }} pb={{ xs: 0, xxs: 1 }}>
          <Typography variant='page-title' style={{ fontWeight: 500, color: LabelColor }}>{t('FIELD_SETTINGS')}</Typography>
        </Block>
        <Block display='flex' fJustify='flex-end' w={{ xs: '50%', xxs: '100%' }}>
          <Button
            text={t('SAVE_CHANGES')}
            onClick={onSubmit}
            loading={submitting}
          />
        </Block>
      </Box>
      {
        user?.type !== RESPONSIBLE &&
        <Block mb={1.375}>
          <Grid gutter="xl">
            <Grid.Col xs={12} sm={6}>
              <SelectInput
                input={{
                  value: selectedFederation?._id || '',
                  onChange: (v: string | null) => changeFederation(v)
                }}
                label={t('FEDERATION')}
                placeholder={t('CHOOSE_FEDERATION')}
                data={federations}
              />
            </Grid.Col>
          </Grid>
        </Block>
      }
      <Box fDirection='column' style={{ borderBottom: `0.063rem solid ${BorderColor}`}}>
        <Box fAlign='center' fWrap='wrap' mb={0.5} w={{ sm: '80%', xxs: '100%' }}>
          <Tab active={type === OFFICIAL} onClick={() => setType(OFFICIAL)}>
            <Typography as='div' variant='body-small' fWeight={500} color='#ffffff'>{t('OFFICIALS')}</Typography>
          </Tab>
          <Tab active={type === ATHLETE} onClick={() => setType(ATHLETE)}>
            <Typography as='div' variant='body-small' fWeight={500} color='#ffffff'>{t('ATHLETES')}</Typography>
          </Tab>
          <Tab active={type === COACH} onClick={() => setType(COACH)}>
            <Typography as='div' variant='body-small' fWeight={500} color='#ffffff'>{t('COACHES')}</Typography>
          </Tab>
          <Tab active={type === PROMOTER} onClick={() => setType(PROMOTER)}>
            <Typography as='div' variant='body-small' fWeight={500} color='#ffffff'>{t('PROMOTERS')}</Typography>
          </Tab>
          <Tab active={type === CLUB} onClick={() => setType(CLUB)}>
            <Typography as='div' variant='body-small' fWeight={500} color='#ffffff'>{t('CLUBS')}</Typography>
          </Tab>
        </Box>
        <Box mb={0.5} mt={{ sm: 0, xxs: 0.5 }} w={{ sm: '80%', xxs: '100%' }}>
          <Box fAlign='center' fJustify='space-between' bRadius={0.25} style={{ backgroundColor: 'rgba(255, 255, 255, 0.05)' }} padding={0.188} pl={0.5}>
            <Typography variant='body-small' lHeight={1.5} pr={0.5} overflow='hidden' style={{ color: 'rgba(255, 255, 255, 0.5)', textOverflow: 'ellipsis' }}>
              {getFederationLink()}
            </Typography>
            <Button 
              type='button'
              text={t('COPY_FORM_LINK')}
              variant={FocusColor}
              onClick={copyFederationLink}
              size='sm'
            />
          </Box>
        </Box>
      </Box>
      <CustomFieldsTable
        columns={columns}
        rows={rows}
        loading={loading}
        tableStyles={{ 
          compact: true
        }}
        pagination={{
          total,
          page: pagination.page,
          setPage: handleNewPage,
          limit: pagination.limit
        }}
      />
    </Block>
  );
};

export default CustomFieldsPage;
