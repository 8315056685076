/* eslint-disable @typescript-eslint/no-explicit-any */
import { ATHLETE_PENDING, COACH_PENDING, NEW_ATHLETE, NEW_COACH, NEW_EVENT, NEW_FIGHT, NEW_OFFICIAL, NEW_PENDING_EVENT, NEW_PENDING_MEMBER_ATHLETE, NEW_PENDING_MEMBER_COACH, NEW_PENDING_MEMBER_OFFICIAL, NEW_PENDING_MEMBER_PROMOTER, NEW_PROMOTER, NEW_REVIEW, Notification, OFFICIAL_PENDING, NEW_CLUB, NEW_PENDING_MEMBER_CLUB, PROMOTER_PENDING, CLUB_PENDING } from '../../models/Notification';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
require('dayjs/locale/pt');
dayjs.extend(isToday);
dayjs.extend(isYesterday);

export const FormatNotificationDate = (date: Date, t: (tag: string, props?: any) => string, language: string) => {
  let dateStr = '';

  if(dayjs(date).isToday()) {
    dateStr = `${t('TODAY')}, ${t('AT')} ${dayjs(date).format('HH:mm')}`;
  }
  else if(dayjs(date).isYesterday()) {
    dateStr = `${t('YESTERDAY')}, ${t('AT')} ${dayjs(date).format('HH:mm')}`;
  }
  else {
    dateStr = `${dayjs(date).locale(language).format('DD MMMM YYYY')} ${t('AT')} ${dayjs(date).format('HH:mm')}`;
  }

  return dateStr;
};

export const NotificationButton = (notification: Notification) => {
  if(notification.record) {
    let textLink = '', link = '';

    switch(notification.type) {
      case NEW_EVENT: 
        textLink = 'VIEW_EVENT';
        link = `/events/${notification.record}`;
        break;
      case NEW_FIGHT: 
        textLink = 'VIEW_FIGHT';
        link = `/fights/${notification.record}`;
        break;
      case NEW_OFFICIAL: 
        textLink = 'VIEW_MEMBER';
        link = `/members/official/${notification.record}`;
        break;
      case NEW_ATHLETE: 
        textLink = 'VIEW_MEMBER';
        link = `/members/athlete/${notification.record}`;
        break;
      case NEW_COACH: 
        textLink = 'VIEW_MEMBER';
        link = `/members/coach/${notification.record}`;
        break;
      case NEW_PROMOTER: 
        textLink = 'VIEW_MEMBER';
        link = `/members/promoter/${notification.record}`;
        break;
      case NEW_CLUB: 
        textLink = 'VIEW_MEMBER';
        link = `/members/club/${notification.record}`;
        break;
      case NEW_REVIEW: 
        textLink = 'VIEW_REVIEWS';
        link = '/reviews';
        break;
      case NEW_PENDING_MEMBER_OFFICIAL: 
        textLink = 'VIEW_MEMBER';
        link = `/members/draft/official/${notification.record}`;
        break;
      case NEW_PENDING_MEMBER_ATHLETE: 
        textLink = 'VIEW_MEMBER';
        link = `/members/draft/athlete/${notification.record}`;
        break;
      case NEW_PENDING_MEMBER_COACH: 
        textLink = 'VIEW_MEMBER';
        link = `/members/draft/coach/${notification.record}`;
        break;
      case NEW_PENDING_MEMBER_PROMOTER: 
        textLink = 'VIEW_MEMBER';
        link = `/members/draft/promoter/${notification.record}`;
        break;
      case NEW_PENDING_MEMBER_CLUB: 
        textLink = 'VIEW_MEMBER';
        link = `/members/draft/club/${notification.record}`;
        break;
      case NEW_PENDING_EVENT: 
        textLink = 'VIEW_EVENT';
        link = `/events/draft/${notification.record}`;
        break;
      case OFFICIAL_PENDING: 
        textLink = 'VIEW_MEMBER';
        link = `/members/official/${notification.record}`;
        break;
      case ATHLETE_PENDING: 
        textLink = 'VIEW_MEMBER';
        link = `/members/athlete/${notification.record}`;
        break;
      case COACH_PENDING: 
        textLink = 'VIEW_MEMBER';
        link = `/members/coach/${notification.record}`;
        break;
      case PROMOTER_PENDING: 
        textLink = 'VIEW_MEMBER';
        link = `/members/promoter/${notification.record}`;
        break;
      case CLUB_PENDING: 
        textLink = 'VIEW_MEMBER';
        link = `/members/club/${notification.record}`;
        break;
      default:
        break;
    }

    if(!!textLink && !!link) return { textLink, link };
  }

  return { textLink: '', link: '' };
};
