import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { User } from '../../../../../models/User';
import { AnyObject } from '../../../../../models/Generic';
import { addItem } from '../../../../../utils/user';
import Typography from '../../../../../components/typography';
import InfoCard, { ListItem } from '../../../common/InfoCard';
import dayjs from 'dayjs';

interface Params {
  official?: User;
  isDraftMember: boolean;
}

const InfoCards: FC<Params> = ({ official, isDraftMember }) => {
  const { t, i18n: { language } } = useTranslation();

  let roles = '';
  if(official?.roles && official.roles.length > 0) {
    official.roles.forEach((role: string) => roles = `${roles}${t(role)}, `);

    roles = roles.slice(0, -2); 
  }

  let martialArts = '';
  if(official?.martial_arts && official.martial_arts.length > 0) {
    official.martial_arts.forEach((martialArt: AnyObject) => martialArts = `${martialArts}${martialArt.name}, `);

    martialArts = martialArts.slice(0, -2); 
  }

  let evaluationClasses = '';
  if(official?.class && official.class.length > 0) {
    official.class.forEach((elem: AnyObject) => evaluationClasses = `${evaluationClasses}${elem.name[language]}, `);

    evaluationClasses = evaluationClasses.slice(0, -2); 
  }

  let address = '';
  if(official?.address) address = official.address;
  if(official?.postal_code) address = address ? `${address} ${official.postal_code}` : official.postal_code;
  if(official?.city) address = address ? `${address}, ${official.city}` : official.city;

  const generalItems: ListItem[] = [];
  addItem(generalItems, t('BIRTH_DATE'), official?.birthday ? dayjs(official?.birthday).format('DD MMM, YYYY') : '');
  addItem(generalItems, t('ADDRESS'), address);
  addItem(generalItems, t('COUNTRY'), official?.country?.name);
  addItem(generalItems, t('GENDER'), t(official?.gender || ''));
  addItem(generalItems, t('NATIONALITY'), official?.nationality);
  addItem(generalItems, t('EMAIL'), official?.email);
  addItem(generalItems, t('PHONE_NUMBER'), official?.phone);
  addItem(generalItems, t('WEIGHT'), official?.weight && `${official.weight} kg`);
  addItem(generalItems, t('HEIGHT'), official?.height && `${official.height} cm`);

  const careerItems: ListItem[] = [];
  addItem(careerItems, t('CLASS'), evaluationClasses);
  addItem(careerItems, t('MARTIAL_ARTS_BACKGROUND'), martialArts);
  addItem(careerItems, t('CERTIFICATIONS'), official?.certifications);
  addItem(careerItems, t('WEIGHT_CLASS'), official?.weight_class?.name && official.weight_class.name[language]);
  addItem(careerItems, t('CLUB'), official?.club?.name);

  const federationItems: ListItem[] = [];
  addItem(federationItems, t('FEDERATION'), official?.federation?.name);
  addItem(federationItems, t('REGISTERED_AS'), roles);

  if(!isDraftMember) {
    addItem(careerItems, t('OVERALL_CONSISTENCY_RATE'), `${official?.stats?.consistency?.toFixed(2)}%`);

    addItem(federationItems, t('AFFILIATION_NUMBER'), official?.associate, 1);
  }

  // Mark last items as "last" to hide the border
  if(generalItems.length > 0) generalItems[generalItems.length - 1] = { ...generalItems[generalItems.length - 1], last: true };
  if(careerItems.length > 0) careerItems[careerItems.length - 1] = { ...careerItems[careerItems.length - 1], last: true };
  if(federationItems.length > 0) federationItems[federationItems.length - 1] = { ...federationItems[federationItems.length - 1], last: true };

  return (
    <>
      {
        generalItems.length > 0 &&
        <InfoCard 
          items={generalItems}
        />
      }
      {
        careerItems.length > 0 &&
        <>
          <Typography variant='body-small' color='#ffffff' pb={0.688} pt={1.5}>{t('CAREER_INFO').toUpperCase()}</Typography>
          <InfoCard 
            items={careerItems}
          />
        </>
      }
      {
        federationItems.length > 0 &&
        <>
          <Typography variant='body-small' color='#ffffff' pb={0.688} pt={1.5}>{t('FEDERATION_INFO').toUpperCase()}</Typography>
          <InfoCard 
            items={federationItems}
          />
        </>
      }
    </>
  );
};

export default InfoCards;
