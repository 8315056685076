/* eslint-disable no-console */
import { AnyObject } from "../../models/Generic";
import { AuthTokenKey, LanguageKey } from "../requests/LocalStorageKeys";

export const openFile = (file: AnyObject, callback?: ((value: string) => void)) => {
  if(!!file.preview) {
    window.open(file.preview, '_blank');
  }
  else if(!!file._id) {
    const authToken = localStorage.getItem(AuthTokenKey);
    const lang = localStorage.getItem(LanguageKey);
    let filename = file._id;

    fetch(`${process.env.REACT_APP_FILE_URL}/document/${file._id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Accept-Language': lang || ''
      }
    })
    .then((resp) => {
      const headerFile = resp?.headers?.get('Content-Disposition');
      if(headerFile) {
        const parts = headerFile.split('=');
        filename = parts[1];
      }

      return resp.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      if(typeof callback === 'function') callback(file._id);
    })
    .catch((e) => console.log(e));
    }
};
