/* eslint-disable @typescript-eslint/no-explicit-any */
import { withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../../hooks/use-validation/use-validation-schema';
import {
  ADMIN,
  User,
  REGULATOR,
  SUPER_ADMIN,
  ATHLETE,
  COACH,
  CLUB
} from '../../../../models/User';
import { BasicForm } from '../../../../styles/BasicStyles';
import { Federation } from '../../../../models/Federation';
import { useState, FC, useEffect, useContext } from 'react';
import { UserContext } from '../../../../Context';
import { validations } from './formHelpers';
import { AnyObject } from '../../../../models/Generic';
import { showConfirm } from '../../../../hooks/show-notification/show-notification';
import Modal, { ModalActions, ModalOverflowContent } from '../../../../components/modal';
import useFetch from 'use-http';
import Button from '../../../../components/button';
import toFormData from '../../../../utils/formdata/ToFormData';
import FormFields from './FormFields';

interface Params {
  opened: boolean;
  onClose: (changed: boolean) => void;
  initialValues?: User;
  isDraftMember?: boolean;
}

const { Form } = withTypes<User>();

const ManageAthleteModal: FC<Params> = ({
  opened,
  onClose,
  initialValues,
  isDraftMember
}) => {
  const { t } = useTranslation();
  const { post, put } = useFetch(isDraftMember ? '/member-drafts' : `/members/${ATHLETE}`);
  const [federations, setFederations] = useState<Federation[]>([]);
  const [clubs, setClubs] = useState<User[]>([]);
  const [coaches, setCoaches] = useState<User[]>([]);
  const federationsHook = useFetch('/federations');
  const membersHook = useFetch('/members');
  const { user } = useContext(UserContext);
  const adminsList = [SUPER_ADMIN, ADMIN, REGULATOR];

  const getClubs = async (federation: AnyObject | undefined) => {
    if (federation) {
      const { data, success } = await membersHook.get(`/${CLUB}/getByFederation/${federation}`);
      if (success) setClubs(data || []);
    }
  };

  const getFederations = async () => {
    const { data, success } = await federationsHook.get('/dropdown');
    if (success) setFederations(data || []);
  };

  const getCoaches = async () => {
    const { data, success } = await membersHook.get(`/${COACH}/dropdown`);
    if (success) setCoaches(data || []);
  };

  useEffect(() => {
    if (user?.type && adminsList.includes(user.type)) getFederations();
    else if(user?.type !== CLUB) getClubs(user?.federation?._id);

    if (opened && initialValues && user?.type !== CLUB) {
      getClubs(initialValues?.federation);
    }

    getCoaches();
  }, [opened]);

  const onSubmit = async (values: User) => {
    // These Users can choose a Federation, otherwise use the current User Federation
    if(user?.type && !adminsList.includes(user.type)) values.federation = user?.federation?._id;

    let error = false;
    const payload = toFormData({ ...values, type: ATHLETE });

    if(values._id) {
      const { success } = await put(`/${values._id}`, payload);
      if(success) onClose(true);
      else error = true;
    } 
    else {
      const { success } = await post(payload);
      if(success) onClose(true);
      else error = true;
    }

    return !error;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(
        user?.type === SUPER_ADMIN ||
          user?.type === ADMIN ||
          user?.type === REGULATOR
          ? validations[0]
          : validations[1]
      )}
      initialValues={initialValues || {}}
      mutators={{
        resetClub: (_, state: any, utils) => {
          getClubs(state.formState.values?.federation);
          utils.changeValue(state, 'club', () => null);
        }
      }}
      render={({
        handleSubmit,
        submitting,
        pristine,
        form: {
          reset,
          mutators: { resetClub }
        }
      }) => { 
        const handleClose = () => {
          if(!pristine) {
            showConfirm({
              title: t('EXIT_BEFORE_SAVE'),
              message: t('EXIT_BEFORE_SAVE_MESSAGE'),
              onConfirm: () => {
                onClose(false);
                reset();
              }
            });
          }
          else {
            onClose(false);
            reset();
          }
        };

        return (
          <Modal
            size={36.25}
            opened={opened}
            onClose={handleClose}
            title={initialValues?._id ? t('EDIT_ATHLETE') : t('CREATE_ATHLETE')}
          >
            <BasicForm onSubmit={async event => {
              const result = await handleSubmit(event);
              if(result) reset();
            }}>
              <ModalOverflowContent>
                <FormFields
                  federations={federations}
                  clubs={clubs}
                  resetClub={resetClub}
                  initialValues={initialValues}
                  coaches={coaches}
                />
              </ModalOverflowContent>
              <ModalActions>
                <Button
                  text={t('CANCEL')}
                  variant="secondary"
                  onClick={handleClose}
                />
                <Button
                  text={initialValues?._id ? t('SAVE') : t('CREATE')}
                  type="submit"
                  loading={submitting}
                  disabled={pristine}
                />
              </ModalActions>
            </BasicForm>
          </Modal>
        );
      }}
    />
  );
};

export default ManageAthleteModal;
