import { Switch, SwitchProps } from '@mantine/core';
import { FieldRenderProps } from 'react-final-form';
import { GenericSwitchInput } from './Generic';
import { SwitchWrapper, InputLabel } from './Styles';
import { InputSize } from './Generic';
import React from 'react';

export interface SwitchInputProps extends Omit<FieldRenderProps<boolean>, 'input'>, SwitchProps {
  afterChange?: (value: boolean) => void;
  input: GenericSwitchInput;
  label?: React.ReactNode;
  size?: InputSize;
  important?: boolean;
}

export const SwitchInput: React.FC<SwitchInputProps> = ({
  input,
  label,
  placeholder,
  afterChange,
  disabled,
  size = 'md',
  important = false,
  ...rest
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.currentTarget.checked;
    input.onChange(value);
    if (afterChange && typeof afterChange === 'function') {
      afterChange(value);
    }
  };

  return (
    <SwitchWrapper size={size}>
      {label && <InputLabel size={size} important={important}>{label}</InputLabel>}
      <Switch
        {...rest}
        label={placeholder}
        disabled={disabled}
        checked={input.value}
        size={size}
        onChange={handleChange}
      />
    </SwitchWrapper>
  );
};

export default SwitchInput;
