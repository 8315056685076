/* eslint-disable @typescript-eslint/no-explicit-any */
import { EmptyTable, ExpandableIcon, HeaderFlex, TablePagination, TableSection } from './styles';
import { Table, TableBody, TableCell, TableHead, TableRow } from './table';
import { Checkbox } from '@mantine/core';
import { TableHeader, LabelColor, DisabledColor } from '../../styles/Colors';
import { Block } from '../../styles/BasicStyles';
import { useTranslation } from 'react-i18next';
import { useDrop } from 'react-dnd';
import { CanOrderArray, FindItem, IsDifferent } from '../../utils/arrays/ArrayFunctions';
import { showError } from '../../hooks/show-notification/show-notification';
import React, { useState, Fragment, useEffect } from 'react';
import Pagination from '../pagination';
import Typography from '../typography';
import Icon from '../icon';
import LoadingOverlay from '../loadingOverlay';
import Row from './row';

interface PaginationObject {
  page: number;
  limit: number;
  filters?: AnyObject | undefined;
  sort?: TableSortProps | undefined;
}

interface AnyObject {
  [key: string]: any;
}

export type RefreshType = (values?: PaginationObject) => void;

export type TableSortProps = {
  field: string;
  order: string;
};

export type TableSelectProps<T = AnyObject> = {
  key: string;
  data: T;
};

export interface TableSelectionProps<T = AnyObject> {
  selected: Array<TableSelectProps<T>>;
  onSelect: (value: Array<TableSelectProps<T>>) => void;
  singleSelect?: boolean;
  selectedKey?: string;
  persistSelect?: boolean;
}

export interface TableColumnsProps<T = AnyObject> {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  align?: string;
  dataIndex?: string;
  render?: (
    value: any,
    data: T,
    index: number,
    refresh?: RefreshType
  ) => React.ReactNode;
  sortable?: boolean;
  width?: string;
  disableRowClick?: boolean;
}

export interface BaseTableProps<T = AnyObject> {
  columns: TableColumnsProps<T>[];
  rows: T[];
  onRowClick?: (value: T, index: number) => void;
  pagination?: {
    page: number;
    setPage: (page: number) => void;
    total: number;
    limit: number;
  };
  loading?: boolean;
  defaultSort?: TableSortProps;
  sortCallback?: (sort: TableSortProps) => void;
  selection?: TableSelectionProps<T>;
  expandable?: (value: T, index: number) => React.ReactNode;
  highlight?: string | ((data: T) => boolean);
  highlightColor?: string;
  emptyTitleTag?: string;
  emptyMessageTag?: string;
  refreshPage?: RefreshType;
  showTableHeaders?: boolean;
  tableStyles?: AnyObject;
  moveItem?: (list: AnyObject[]) => void;
  disableOrder?: boolean;
  fixColumns?: number[];
}

export const BaseTable = <T extends AnyObject>() => {
  const TypedBaseTable: React.FC<BaseTableProps<T>> = ({
    columns,
    rows,
    onRowClick,
    pagination,
    loading = false,
    defaultSort = { field: '', order: '' },
    sortCallback,
    selection,
    expandable,
    highlight,
    highlightColor,
    refreshPage,
    emptyTitleTag = 'TABLE_EMPTY_TITLE',
    emptyMessageTag = 'TABLE_EMPTY_MESSAGE',
    showTableHeaders = true,
    tableStyles,
    moveItem,
    disableOrder = false,
    fixColumns
  }) => {
    const [tempRows, setTempRows] = useState<T[]>([]);
    const [sort, setSort] = useState<TableSortProps>(defaultSort);
    const [open, setOpen] = useState<number | undefined>();
    const [orderLoading, setOrderLoading] = useState(false);
    const selectedKey = selection?.selectedKey || '_id';
    const { t } = useTranslation();

    useEffect(() => {
      setTempRows(rows);
    }, [rows]);

    const [, drop] = useDrop(() => ({
      accept: 'block',
      drop: () => ({ name: 'blocks' })
    }));

    const moveRow = async (id: string, atIndex: number, save: boolean) => {
      if(!!moveItem) {
        setOrderLoading(true);

        if(save) {
          // Save only if something changed from the original array
          const canSave = IsDifferent(rows, tempRows);

          if(canSave) {
            await moveItem(tempRows);
          }
          else {
            showError({
              title: t('ERROR'),
              message: t('CANNOT_ORDER_FIGHTS')
            })
          }
        }
        else {
          const { item, index } = FindItem(tempRows, id);

          let canMove = false;
          if(item.order_priority === 1) canMove = true;
          else canMove = CanOrderArray(item, tempRows, index, atIndex);

          if(canMove) {
            const aux = [...tempRows];
            aux.splice(index, 1);
            aux.splice(atIndex, 0, item);
            setTempRows(aux);
          }
        }

        setOrderLoading(false);
      }
    };

    const handleSort = (column: TableColumnsProps<T>) => {
      let newSort = { field: column.dataIndex || '', order: 'asc' };
      if (sort.field === column.dataIndex) {
        if (sort.order === 'asc') {
          newSort = { field: column.dataIndex, order: 'desc' };
        } else newSort = { field: '', order: '' };
      }
      setSort(newSort);
      if (sortCallback) sortCallback(newSort);
    };

    const handleCollapse = (index: number) => {
      if (open === index) setOpen(undefined);
      else setOpen(index);
    };

    const checkAll = () => {
      if (selection) {
        if (selection.selected.length >= rows.length) {
          selection.onSelect([]);
        } else {
          const result = rows.map((row: T) => ({
            key: row[selectedKey],
            data: row,
          }));
          selection.onSelect(result);
        }
      }
    };

    const checkRow = (row: T) => {
      if (selection) {
        if (selection.singleSelect) {
          selection.onSelect([{ key: row[selectedKey], data: row }]);
        } else {
          const selected = [...selection.selected];
          const found = selected.findIndex(x => x.key === row[selectedKey]);
          if (found > -1) {
            selected.splice(found, 1);
          } else selected.push({ key: row[selectedKey], data: row });
          selection.onSelect(selected);
        }
      }
    };

    const renderHeader = (column: TableColumnsProps<T>, index: number) => {
      if (column.sortable && column.dataIndex) {
        return (
          <TableCell key={index} width={column.width} className={fixColumns?.includes(index) ? 'sticky' : ''}>
            <HeaderFlex onClick={() => handleSort(column)}>
              <div>
                <Typography as='div' variant='table-header'>{column.title}</Typography>
                {!!column.subtitle && <Typography as='div' variant='sidebar-group' style={{ color: DisabledColor }}>{column.subtitle}</Typography>}
              </div>
              {
                sort.field === column.dataIndex && sort.order === 'asc' ?
                <Icon icon='outlined_chevronUp' color={TableHeader} style={{ paddingLeft: '0.3rem' }} />
                : sort.field === column.dataIndex && sort.order === 'desc' ?
                <Icon icon='outlined_chevronDown' color={TableHeader} style={{ paddingLeft: '0.3rem' }} />
                :
                <Icon icon='outlined_selector' color={TableHeader} style={{ paddingLeft: '0.3rem' }} />
              }
            </HeaderFlex>
          </TableCell>
        );
      }
      return (
        <TableCell key={index} width={column.width} className={fixColumns?.includes(index) ? 'sticky' : ''}>
          <Typography as='div' variant='table-header' tAlign={column.align || 'left'}>{column.title}</Typography>
          {!!column.subtitle && <Typography as='div' variant='sidebar-group' style={{ color: DisabledColor }}>{column.subtitle}</Typography>}
        </TableCell>
      );
    };

    const renderColumn = (
      rIndex: number,
      data: T,
      column: TableColumnsProps<T>
    ) => {
      if (column.render) {
        const value = column.dataIndex ? data[column.dataIndex] : data;
        return column.render(value, data, rIndex, refreshPage);
      }
      return <Typography variant='table-header' tAlign={column.align || 'left'}>{column.dataIndex ? data[column.dataIndex] || '' : ''}</Typography>;
    };

    const setHighlight = (data: T) => {
      if (typeof highlight === 'string') {
        return !!data[highlight];
      }
      if (typeof highlight === 'function') {
        return highlight(data);
      }
      return false;
    };

    const data = tempRows?.map((row, rIndex) => (
      <Fragment key={rIndex}>
        <Row 
          rIndex={rIndex}
          rows={tempRows}
          row={row}
          handleCollapse={handleCollapse}
          renderColumn={renderColumn}
          highlightColor={highlightColor}
          setHighlight={setHighlight}
          checkRow={checkRow}
          expandable={expandable}
          selection={selection}
          onRowClick={onRowClick}
          columns={columns}
          selectedKey={selectedKey}
          open={open}
          allowMove={!!moveItem}
          moveRow={moveRow}
          disableOrder={disableOrder}
          fixColumns={fixColumns}
        />
      </Fragment>
    ));

    return (
      <TableSection>
        <Block>
          <LoadingOverlay radius={16} visible={loading || orderLoading} />
          <Table showTableHeaders={showTableHeaders} tableStyles={tableStyles}>
            {
              showTableHeaders &&
              <TableHead>
                <TableRow>
                  {expandable && (
                    <TableCell style={{ width: '3.125rem', maxWidth: '3.125rem' }} />
                  )}
                  {selection && (
                    <TableCell style={{ width: '3.125rem', maxWidth: '3.125rem' }}>
                      {!selection.singleSelect && (
                        <ExpandableIcon>
                          <Checkbox
                            radius="sm"
                            name="all"
                            value="all"
                            checked={
                              selection.selected.length > 0 &&
                              selection.selected.length >= tempRows?.length
                            }
                            indeterminate={
                              selection.selected.length > 0 &&
                              selection.selected.length < tempRows?.length
                            }
                            onChange={checkAll}
                            size="xs"
                          />
                        </ExpandableIcon>
                      )}
                    </TableCell>
                  )}
                  {columns.map(renderHeader)}
                  {!!moveItem && (
                    <TableCell style={{ width: '50px', maxWidth: '50px' }} />
                  )}
                </TableRow>
              </TableHead>
            }
            <TableBody ref={drop}>{data}</TableBody>
          </Table>
          {
            tempRows?.length === 0 &&
            <EmptyTable>
              <Icon icon='outlined_clipboard' color={LabelColor} size={5} />
              <Typography variant='title' style={{ margin: '0.625rem 0', color: LabelColor }}>
                {t(emptyTitleTag || 'NO_RESULTS')}
              </Typography>
              <Typography variant="body-small" style={{ color: LabelColor }}>
                {t(emptyMessageTag || 'NO_RESULTS_TEXT')}
              </Typography>
            </EmptyTable>
          }
        </Block>
        {
          pagination && tempRows?.length > 0 &&
          <TablePagination>
            <Pagination
              page={pagination.page}
              setPage={pagination.setPage}
              total={pagination.total}
              limit={pagination.limit}
            />
          </TablePagination>
        }
      </TableSection>
    );
  };
  return TypedBaseTable;
};

export default BaseTable;
