import { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../Context';
import { ADMIN, ATHLETE, COACH, OFFICIAL, PROMOTER, REGULATOR, SUPER_ADMIN, RESPONSIBLE, CLUB } from '../../models/User';
import { useTranslation } from 'react-i18next';
import { Block, Box } from '../../styles/BasicStyles';
import { Federation } from '../../models/Federation';
import { LabelColor, G600 } from '../../styles/Colors';
import { DashboardInfo } from '../../models/Dashboard';
import LoadingOverlay from '../../components/loadingOverlay';
import SelectInput from '../../components/inputs/SelectInput';
import useFetch from 'use-http';
import Typography from '../../components/typography';
import AthleteDetailPage from '../members/athletes/detail';
import CoachDetailPage from '../members/coaches/detail';
import OfficialDetailPage from '../members/officials/detail';
import ClubDetailPage from '../members/clubs/detail';
import Cards from './Cards';
import SmallCards from './SmallCards';
import Graphs from './Graphs';

const DashboardPage = () => {
  const [loading, setLoading] = useState(true);
  const [federation, setFederation] = useState<string | null>(null);
  const [federations, setFederations] = useState<Federation[]>([]);
  const [info, setInfo] = useState<DashboardInfo>({});
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const federationsHook = useFetch('/federations/dropdown');
  const { get } = useFetch('/dashboard/info');
  const SuperAdminList = [SUPER_ADMIN, ADMIN, REGULATOR];
  const AdminList = [RESPONSIBLE, PROMOTER];

  useEffect(() => {
    const init = async () => {
      const federationsResult = await federationsHook.get();
      setFederations(federationsResult?.data || []);
      setFederation('all');
    };

    if(user?.type && SuperAdminList.includes(user?.type)) init();
    else if(user?.type && AdminList.includes(user?.type)) setFederation(user?.federation?._id);
  }, []);

  useEffect(() => {
    const getData = async () => {
      const info = await get(`/${federation}`);

      setInfo(info?.data || {});
      setLoading(false);
    };

    if(federation) getData();
  }, [federation]);

  return (
    <>
      {
        user?.type === ATHLETE ?
        <AthleteDetailPage propsId={user._id} />
        :
        user?.type === COACH ?
        <CoachDetailPage propsId={user._id} />
        :
        user?.type === OFFICIAL ?
        <OfficialDetailPage propsId={user._id} />
        :
        user?.type === CLUB ?
        <ClubDetailPage propsId={user._id} />
        :
        <>
          <LoadingOverlay visible={loading} />
          {
            user?.type && SuperAdminList.includes(user?.type) &&
            <Box 
              fAlign='center' 
              fJustify={{ xs: 'flex-end', xxs: 'flex-start' }} 
              mt={1} 
              mb={2} 
              padding={{ xs: '0 0.5', xxs: 0 }}
            >
              <Block 
                display='flex' 
                fAlign='center' 
                fJustify={{ xs: 'flex-end', xxs: 'flex-start' }} 
                fWrap='wrap' 
                bgColor={G600} 
                padding='0.5' 
                bRadius={0.5}
                w={{ xs: 'auto', xxs: '100%' }}
              >
                <Block pr={0.75} pb={{ xs: 0, xxs: 0.25 }} w={{ xs: 'auto', xxs: '100%' }}>
                  <Typography variant='body' fWeight={500} style={{ color: LabelColor }}>{t('SELECT_FEDERATION')}:</Typography>
                </Block>
                <Block maxW={{ xs: 17, xxs: '100%' }} w={{ xs: 'auto', xxs: '100%' }}>
                  <SelectInput
                    input={{
                      value: federation,
                      onChange: (v: string | null) => { setLoading(true); setFederation(v || 'all'); }
                    }}
                    placeholder={t('CHOOSE_FEDERATION')}
                    data={federations}
                    clearable
                  />
                </Block>
              </Block>
            </Box>
          }
          <Cards info={info} />
          <SmallCards info={info} />
          <Graphs info={info} />
        </>
      }
    </>
  );
};

export default DashboardPage;
