import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { User, UserStatusList } from '../../../models/User';
import { Block, Box } from '../../../styles/BasicStyles';
import { MenuBgColor, BorderColor, DisabledColor, B300, B100 } from '../../../styles/Colors';
import { EventDraftTypes } from '../../../utils/events';
import Typography from '../../../components/typography';
import UserAvatar from '../../../components/userAvatar';
import Label from '../../../components/label';

interface Params {
  userInfo?: User;
  isDraftMember: boolean;
}

const MainCard: FC<Params> = ({ userInfo, isDraftMember }) => {
  const { t } = useTranslation();
  const statusList = isDraftMember ? EventDraftTypes : UserStatusList;

  const renderStatus = () => 
    !!userInfo?.status &&
    <Label 
      text={t(userInfo.status).toUpperCase()} 
      bgColor={statusList.find(elem => elem._id === userInfo.status)?.bgColor} 
      fontColor={statusList.find(elem => elem._id === userInfo.status)?.fontColor} 
      fSize={0.875}
      lHeight={1.25}
    />;

  return (
    <Block bgColor={MenuBgColor} padding='1 2' bRadius={0.5} style={{ border: `0.063rem solid ${BorderColor}`}} w='100%' mb={1.625}>
      <Box fAlign='center' fJustify='space-between'>
        <Block display='flex' fDirection={{ xs: 'row', xxs: 'column' }} fAlign={{ xs: 'center', xxs: 'flex-start' }} pr={{ xs: 1, xxs: 0 }}>
          <Block display='flex' fShrink={0}>
            <UserAvatar user={userInfo} size='xl' />
          </Block>
          <Block display='flex' fDirection='column' ml={{ xs: 1.625, xxs: 0 }} mt={{ xs: 0, xxs: 1 }}>
            <Block display={{ xs: 'none', xxs: 'block' }} pb={0.5}>
              {renderStatus()}
            </Block>
            <Typography variant='auth-title' color='#ffffff' pb={0.5}>{userInfo?.name}</Typography>
            <Typography variant='dropdown-menu' lHeight={1.188} style={{ color: DisabledColor }}>
              {t(userInfo?.type || '').toUpperCase()}
            </Typography>
            {
              userInfo?.allow_manage &&
              <Block pt={0.5}>
                <Label 
                  text={t('ATHLETE_PROFILE_MANAGED')} 
                  bgColor={B300} 
                  fontColor={B100} 
                  fSize={0.75}
                  lHeight={1.125}
                  
                />
              </Block>
            }
          </Block>
        </Block>
        <Block display={{ xs: 'block', xxs: 'none' }}>
          {renderStatus()}
        </Block>
      </Box>        
    </Block>
  );
};

export default MainCard;
