import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { User } from '../../../../../models/User';
import { Box } from '../../../../../styles/BasicStyles';
import { FirstColumn } from '../../../common/styles';
import { capitalizeString } from '../../../../../utils/strings';
import Typography from '../../../../../components/typography';
import InfoCards from './InfoCards';
import Documents from '../../../common/Documents';
import DraftFileList from '../../../common/DraftFileList';
import StatsCard from '../../../common/StatsCard';

interface Params {
  club?: User;
  getInfo: () => void;
  isDraftMember: boolean;
}

const Overview: FC<Params> = ({ club, getInfo, isDraftMember }) => {
  const { t } = useTranslation();
  
  return (
    <Box mt={1.5} fDirection='column' mb={5}>
      <Typography variant='body-small' color='#ffffff' pb={0.688}>{t('GENERAL_INFO').toUpperCase()}</Typography>
      <Box fWrap='wrap'>
        <FirstColumn fullWidth={isDraftMember}>
          <InfoCards club={club} isDraftMember={isDraftMember} />
          {
            isDraftMember ?
            <DraftFileList initialValues={club} />
            :
            <Documents propsId={club?._id} getInfo={getInfo} />
          }
        </FirstColumn>
        {
          !isDraftMember &&
          <Box fDirection='column' w={{ lg: '30%', xxs: '100%' }} pl={{ lg: 1.875, xxs: 0 }} pt={{ lg: 0, xxs: 1.875 }}>
            <StatsCard 
              items={[
                {
                  title: `${club?.stats?.totalWon} ${capitalizeString(t('FIGHTS'))}`,
                  subtitle: t('WON'),
                  bgColor: 'rgba(61, 205, 179, 0.15)',
                  fontColor: 'rgba(61, 205, 179, 0.5)'
                },
                {
                  title: `${club?.stats?.totalDraw} ${capitalizeString(t('FIGHTS'))}`,
                  subtitle: t('DRAW'),
                  bgColor: 'rgba(79, 194, 244, 0.15)',
                  fontColor: 'rgba(79, 194, 244, 0.5)'
                },
                {
                  title: `${club?.stats?.totalLost} ${capitalizeString(t('FIGHTS'))}`,
                  subtitle: t('LOST'),
                  bgColor: 'rgba(255, 160, 0, 0.15)',
                  fontColor: 'rgba(255, 160, 0, 0.5)'
                },
                {
                  title: `${club?.stats?.totalUpcoming} ${capitalizeString(t('FIGHTS'))}`,
                  subtitle: t('UPCOMING'),
                  bgColor: 'rgba(237, 106, 159, 0.15)',
                  fontColor: 'rgba(237, 106, 159, 0.5)'
                }
              ]}
            />
          </Box>
        }
      </Box>
    </Box>
  );
};

export default Overview;
