import { FC } from 'react';
import { AnyObject } from '../../models/Generic';
import { useTranslation } from 'react-i18next';
import { Block } from '../../styles/BasicStyles';
import { showConfirm } from '../../hooks/show-notification/show-notification';
import DropdownMenu, { ActionProps } from '../dropdownMenu';
import Typography from '../typography';
import Icon from '../icon';

interface LabelDropdownProps {
  status: string;
  list: AnyObject[];
  id: string;
  onConfirmStatusToogle: (id: string, status: string) => void;
  disabled?: boolean;
}

export const LabelDropdown: FC<LabelDropdownProps> = ({ status, list, id, onConfirmStatusToogle, disabled = false }) => {
  const { t } = useTranslation();
  
  const renderStatus = () => {
    const statusDetail = list.find(elem => elem._id === status);

    if(!statusDetail) return null;

    return (
      <Block display='flex' fDirection='row' fAlign='center' style={{ backgroundColor: statusDetail.bgColor }} bRadius={6.25} padding='0.375 0.813'>
        {!!statusDetail.icon && <Icon icon={statusDetail.icon} color={statusDetail.iconColor} style={{ paddingRight: '0.375rem' }} size={1.125} />}
        <Typography variant='multi-select' style={{ color: statusDetail.fontColor }} pr={!disabled ? 0.375 : 0}>{t(statusDetail.name)}</Typography>
        {!disabled && <Icon icon='outlined_chevronDown' color={statusDetail.fontColor} size={0.75} />}
      </Block>
    );
  };

  const renderStatusActions = () => {
    if(disabled) return [];
    
    const filtered = list.filter(elem => elem._id !== status);

    if(filtered.length > 0) {
      const result: ActionProps[] = [];

      filtered.forEach((elem: AnyObject, index: number) => {
        result.push({
          type: 'link',
          label: t(elem.name),
          onClick: () => handleStatusToogle(elem.name)
        });

        if(index < (filtered.length - 1)) {
          result.push({
            type: 'divider'
          });
        }
      });

      return result;
    }
    return [];
  };

  const handleStatusToogle = async (status: string) => {
    showConfirm({
      title: t('CHANGE_STATUS'),
      message: t('CHANGE_STATUS_MESSAGE').replace('#STATUS#', t(status).toUpperCase()),
      onConfirm: () => onConfirmStatusToogle(id, status)
    });
  };

  return (
    <DropdownMenu 
      render={renderStatus()}
      menuPosition='bottom-end'
      actions={renderStatusActions()} 
    />
  );
};

export default LabelDropdown;
