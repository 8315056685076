import { transitionsDelay } from '../../../styles/BasicStyles';
import { breakpoints } from '../../../styles/Breakpoints';
import styled from 'styled-components';

export const Tab = styled.div<{ active: boolean }>`
  width: 20%;
  padding-right: 0.313rem;

  &:last-child {
    padding-right: 0;
  }

  & div {
    padding: 0.625rem 0rem;
    border-radius: 0.25rem;
    background-color: ${({ active }) => active ? 'rgba(255, 255, 255, 0.2)' : 'transparent'};
    border: 0.063rem solid ${({ active }) => active ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.05)'};
    cursor: pointer;
    transition: opacity ${transitionsDelay} linear, border ${transitionsDelay} linear;
    text-align: center;
    width: 100%;

    &:hover {
      opacity: 0.7;
    }
  }

  @media ${breakpoints.xs} {
    width: 100%;
    padding-right: 0;
    padding-bottom: 0.313rem;
  }
`;
