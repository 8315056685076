import {
  SUPER_ADMIN,
  ADMIN,
  REGULATOR,
  RESPONSIBLE,
  PROMOTER,
  OFFICIAL,
  CLUB
} from '../../models/User';
import Icon from '../icon';

export type MenuType = 'link' | 'dropdown';

export interface SidebarGroupProps {
  name?: string;
  options: SidebarOptionProps[];
}

export interface SidebarOptionProps {
  type: MenuType;
  number?: number;
  name?: string;
  url?: string;
  icon?: React.ReactElement;
  options?: SidebarOptionProps[];
  superuser?: boolean;
  roles?: string[];
}

export const sidebarOptions: SidebarGroupProps[] = [
  {
    name: 'OVERVIEW',
    options: [
      {
        type: 'link',
        name: 'OVERVIEW',
        url: '/',
        icon: <Icon icon="bold_template" size={1.25} />,
        roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER]
      }
    ]
  },
  {
    name: 'MAIN_MENU',
    options: [
      {
        type: 'link',
        name: 'MY_PROFILE',
        url: '/',
        icon: <Icon icon="outlined_user" size={1.25} />,
        roles: [OFFICIAL, CLUB]
      },
      {
        type: 'link',
        name: 'MY_PROFILE',
        url: '/promoter-profile',
        icon: <Icon icon="outlined_user" size={1.25} />,
        roles: [PROMOTER]
      },
      {
        type: 'dropdown',
        number: 1,
        name: 'EVENTS',
        url: '/events',
        icon: <Icon icon="bold_bubbleChart" size={1.25} />,
        roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER, OFFICIAL],
        options: [
          {
            type: 'link',
            name: 'UPCOMING_EVENTS',
            url: '/events/upcoming',
            roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER, OFFICIAL]
          },
          {
            type: 'link',
            name: 'PAST_EVENTS',
            url: '/events/past',
            roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER, OFFICIAL]
          },
          {
            type: 'link',
            name: 'EVENT_DRAFTS',
            url: '/events/draft',
            roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER]
          }
        ]
      },
      {
        type: 'dropdown',
        number: 2,
        name: 'MEMBERS',
        url: '/members',
        icon: <Icon icon="bold_users" size={1.25} />,
        roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER, CLUB],
        options: [
          {
            type: 'link',
            name: 'OFFICIALS',
            url: '/members/official',
            roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
          },
          {
            type: 'link',
            name: 'ATHLETES',
            url: '/members/athlete',
            roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER, CLUB]
          },
          {
            type: 'link',
            name: 'COACHES',
            url: '/members/coach',
            roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, CLUB]
          },
          {
            type: 'link',
            name: 'PROMOTERS',
            url: '/members/promoter',
            roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
          },
          {
            type: 'link',
            name: 'CLUBS',
            url: '/members/club',
            roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
          },
          {
            type: 'link',
            name: 'MEMBER_DRAFTS',
            url: '/members/pending',
            roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, CLUB]
          },
          {
            type: 'link',
            name: 'APPLICATIONS',
            url: '/members/draft',
            roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, CLUB]
          },
          {
            type: 'link',
            name: 'CLUB_REQUESTS',
            url: '/members/club-request',
            roles: [CLUB]
          }
        ]
      },
      {
        type: 'link',
        name: 'REVIEWS',
        url: '/reviews',
        icon: <Icon icon="outlined_exclamationCircle" size={1.25} />,
        roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
      },
      {
        type: 'link',
        name: 'TRANSLATIONS',
        url: '/translations',
        icon: <Icon icon="outlined_globe" size={1.25} />,
        superuser: true
      },
      {
        type: 'link',
        name: 'EMAIL_TEMPLATES',
        url: '/email-templates',
        icon: <Icon icon="outlined_mail" size={1.25} />,
        superuser: true
      },
      {
        type: 'link',
        name: 'DYNAMIC_PAGES',
        url: '/dynamic-pages',
        icon: <Icon icon="outlined_documentText" size={1.25} />,
        superuser: true
      },
      {
        type: 'link',
        name: 'LOGS',
        url: '/logs',
        icon: <Icon icon="outlined_database" size={1.25} />,
        superuser: true
      }
    ]
  }
];
