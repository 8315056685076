/* eslint-disable @typescript-eslint/no-explicit-any */
import { User, SUPER_ADMIN, ADMIN, REGULATOR, ATHLETE, OFFICIAL, COACH, PROMOTER, CLUB } from '../../models/User';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext } from 'react';
import { LabelColor } from '../../styles/Colors';
import { showDelete } from '../../hooks/show-notification/show-notification';
import { Federation } from '../../models/Federation';
import { UserContext } from '../../Context';
import { GetAthleteInitialValues, GetClubInitialValues, GetCoachInitialValues, GetOfficialInitialValues, GetPromoterInitialValues } from '../../utils/modalValues';
import { GetEventDraftType } from '../../utils/events';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import DefaultListPage from '../../components/page/DefaultListPage';
import Icon from '../../components/icon';
import Typography from '../../components/typography';
import DropdownMenu, { ActionProps } from '../../components/dropdownMenu';
import BaseTable from '../../components/table';
import MemberDraftsFilters from './MemberDraftsFilters';
import Label from '../../components/label';
import ManageAthleteDraftModal from '../members/athletes/form';
import ManageOfficialDraftModal from '../members/officials/form';
import ManageCoachDraftModal from '../members/coaches/form';
import ManagePromoterDraftModal from '../members/promoters/form';
import ManageClubDraftModal from '../members/clubs/form';
import UserCard from '../../components/userCard';

const MemberDraftsListPage = DefaultListPage<User>();
const MemberDraftsTable = BaseTable<User>();

const MemberDraftsPage = () => {
  const [federations, setFederations] = useState<Federation[]>([]);
  const [athleteModal, setAthleteModal] = useState<{ open: boolean; initialValues?: User; }>({ open: false });
  const [officialModal, setOfficialModal] = useState<{ open: boolean; initialValues?: User; }>({ open: false });
  const [coachModal, setCoachModal] = useState<{ open: boolean; initialValues?: User; }>({ open: false });
  const [promoterModal, setPromoterModal] = useState<{ open: boolean; initialValues?: User; }>({ open: false });
  const [clubModal, setClubModal] = useState<{ open: boolean; initialValues?: User; }>({ open: false });
  const { t } = useTranslation();
  const memberHooks = useFetch('/member-drafts');
  const federationsHook = useFetch('/federations');
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      if(user?.type === SUPER_ADMIN || user?.type === ADMIN || user?.type === REGULATOR) {
        const federationsResult = await federationsHook.get('/dropdown');
        setFederations(federationsResult?.data || []);
      }
    };

    init();
  }, []);

  return (
    <MemberDraftsListPage
      translate={t}
      request={{
        get: memberHooks.get,
        searchFields: ['name', 'email']
      }}
      storage='MEMBER_DRAFTS_FILTERS'
      header={{ title: t('APPLICATIONS') }}
      filters={({ filters, handleChange }) => 
        <MemberDraftsFilters 
          filters={filters} 
          handleChange={handleChange}
          federations={federations} 
        />
      }
      countLabel={`${t('MEMBERS').toLowerCase()}`}
    >
      {({ rows, loading, pagination, handleNewSort, refreshPage }) => {
        const handleCloseModal = (changed: boolean) => {
          if (changed) refreshPage();
          setAthleteModal({ open: false });
          setOfficialModal({ open: false });
          setCoachModal({ open: false });
          setPromoterModal({ open: false });
          setClubModal({ open: false });
        };

        const handleDelete = async (id: string) => {
          showDelete({
            title: t('DELETE_MEMBER'),
            message: t('DELETE_MEMBER_MESSAGE'),
            onConfirm: async () => {
              await memberHooks.del(`/${id}`);
              refreshPage();
            }
          });
        };

        const openMember = async (member: User) => {
          if(member.type === ATHLETE) return navigate(`/members/draft/athlete/${member._id}`);
          else if(member.type === OFFICIAL) return navigate(`/members/draft/official/${member._id}`);
          else if(member.type === COACH) return navigate(`/members/draft/coach/${member._id}`);
          else if(member.type === PROMOTER) return navigate(`/members/draft/promoter/${member._id}`);
          else if(member.type === CLUB) return navigate(`/members/draft/club/${member._id}`);
        };

        const columns = [
          { 
            title: t('NAME'), 
            dataIndex: 'name', 
            sortable: true,
            render: (_: string, row: User) => <UserCard user={row} showEmail noStatus />
          },
          {
            title: t('TYPE'),
            dataIndex: 'type',
            sortable: true,
            render: (value: string) => <Typography variant='table-header'>{t(value)}</Typography>
          },
          {
            title: t('COUNTRY'),
            dataIndex: 'country',
            sortable: true,
            render: (_: any, row: User) => <Typography variant='table-header'>{row.country?.name}</Typography>
          },
          {
            title: t('STATUS'),
            key: 'status',
            render: (_: any, row: User) => {
              // Same function and Status as for Event Drafts
              const status = GetEventDraftType(row.status);

              return (
                <Label 
                  text={t(status?.name)} 
                  fontColor={status?.fontColor} 
                  bgColor={status?.bgColor} 
                />
              );
            }
          },
          { 
            title: t('MORE'),
            key: 'actions', 
            disableRowClick: true,
            render: (row: User) => {
              const options: ActionProps[] = [
                {
                  type: 'link',
                  label: t('EDIT'),
                  onClick: () => 
                    row.type === ATHLETE ?
                    setAthleteModal({ open: true, initialValues: GetAthleteInitialValues(row) })
                    :
                    row.type === OFFICIAL ?
                    setOfficialModal({ open: true, initialValues: GetOfficialInitialValues(row) })
                    :
                    row.type === COACH ?
                    setCoachModal({ open: true, initialValues: GetCoachInitialValues(row) })
                    :
                    row.type === PROMOTER ?
                    setPromoterModal({ open: true, initialValues: GetPromoterInitialValues(row) })
                    :
                    row.type === CLUB ?
                    setClubModal({ open: true, initialValues: GetClubInitialValues(row) })
                    :
                    null
                },
                {
                  type: 'divider'
                },
                {
                  type: 'link',
                  label: t('DELETE'),
                  onClick: () => handleDelete(row._id)
                }
              ];

              return (
                <DropdownMenu 
                  render={<Icon icon='outlined_dotsHorizontal' color={LabelColor} />}
                  menuPosition='bottom-end'
                  actions={options} 
                />
              );
            }
          }
        ];

        // Show Federation column for Admin users
        if(user?.type === SUPER_ADMIN || user?.type === ADMIN || user?.type === REGULATOR) {
          columns.splice(2, 0, {
            title: t('FEDERATION'),
            dataIndex: 'federation',
            sortable: true,
            render: (_: any, user: User) => <Typography variant='table-header'>{user.federation?.name}</Typography>
          });
        }

        return (
          <>
            <MemberDraftsTable
              columns={columns}
              rows={rows}
              loading={loading}
              defaultSort={pagination.sort}
              sortCallback={handleNewSort}
              onRowClick={(row) => openMember(row)}
            />
            <ManageAthleteDraftModal
              opened={athleteModal.open}
              initialValues={athleteModal.initialValues}
              onClose={handleCloseModal}
              isDraftMember={true}
            />
            <ManageOfficialDraftModal
              opened={officialModal.open}
              initialValues={officialModal.initialValues}
              onClose={handleCloseModal}
              isDraftMember={true}
            />
            <ManageCoachDraftModal
              opened={coachModal.open}
              initialValues={coachModal.initialValues}
              onClose={handleCloseModal}
              isDraftMember={true}
            />
            <ManagePromoterDraftModal
              opened={promoterModal.open}
              initialValues={promoterModal.initialValues}
              onClose={handleCloseModal}
              isDraftMember={true}
            />
            <ManageClubDraftModal
              opened={clubModal.open}
              initialValues={clubModal.initialValues}
              onClose={handleCloseModal}
              isDraftMember={true}
            />
          </>
        );
      }}
    </MemberDraftsListPage>
  );
};

export default MemberDraftsPage;
