import { useTranslation } from 'react-i18next';
import { Tabs } from '@mantine/core';
import { TabsWrapper } from '../../components/tabs/styles';
import {
  SUPER_ADMIN,
  ADMIN,
  REGULATOR,
  RESPONSIBLE
} from '../../models/User';
import EditProfile from './profile';
// import Notifications from './notifications';
import Users from './users';
import Federations from './federations';
import CustomFields from './customFields';
import CanBeRendered from '../../utils/user/permissions';
import DocumentsSettings from './documentsSettings';
import Configs from './configs';

const ProfilePage = () => {
  const { t } = useTranslation();

  return (
    <TabsWrapper>
      <Tabs radius="md" defaultValue="profile">
        <CanBeRendered types={[SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]}>
          <Tabs.List>
            <Tabs.Tab value="profile">{t('PROFILE')}</Tabs.Tab>
            <CanBeRendered types={[SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]}>
              <Tabs.Tab value="users">{t('USERS')}</Tabs.Tab>
            </CanBeRendered>
            <CanBeRendered types={[SUPER_ADMIN, ADMIN, REGULATOR]}>
              <Tabs.Tab value="federations">{t('FEDERATIONS')}</Tabs.Tab>
            </CanBeRendered>
            <CanBeRendered types={[SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]}>
              <Tabs.Tab value="custom_fields">{t('FIELD_SETTINGS')}</Tabs.Tab>
            </CanBeRendered>
            <CanBeRendered types={[SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]}>
              <Tabs.Tab value="documents_settings">{t('DOCUMENTS_SETTINGS')}</Tabs.Tab>
            </CanBeRendered>
            {/* Email Notifications won't exist for now */}
            {/* <CanBeRendered types={[SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER]}>
              <Tabs.Tab value="notifications">{t('NOTIFICATIONS')}</Tabs.Tab>
            </CanBeRendered> */}
            <CanBeRendered types={[SUPER_ADMIN]}>
              <Tabs.Tab value="configs">{t('CONFIGS')}</Tabs.Tab>
            </CanBeRendered>
          </Tabs.List>
        </CanBeRendered>
        <Tabs.Panel value="profile">
          <EditProfile />
        </Tabs.Panel>
        <CanBeRendered types={[SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]}>
          <Tabs.Panel value="users">
            <Users />
          </Tabs.Panel>
        </CanBeRendered>
        <CanBeRendered types={[SUPER_ADMIN, ADMIN, REGULATOR]}>
          <Tabs.Panel value="federations">
            <Federations />
          </Tabs.Panel>
        </CanBeRendered>
        <CanBeRendered types={[SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]}>
          <Tabs.Panel value="custom_fields">
            <CustomFields />
          </Tabs.Panel>
        </CanBeRendered>
        <CanBeRendered types={[SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]}>
          <Tabs.Panel value="documents_settings">
            <DocumentsSettings />
          </Tabs.Panel>
        </CanBeRendered>
        {/* <CanBeRendered types={[SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER]}>
          <Tabs.Panel value="notifications">
            <Notifications />
          </Tabs.Panel>
        </CanBeRendered> */}
        <CanBeRendered types={[SUPER_ADMIN]}>
          <Tabs.Panel value="configs">
            <Configs />
          </Tabs.Panel>
        </CanBeRendered>
      </Tabs>
    </TabsWrapper>
  );
};

export default ProfilePage;
