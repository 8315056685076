import { AnyObject } from './Generic';

export const SUPER_ADMIN = 'SUPER_ADMIN';
export const ADMIN = 'ADMIN';
export const REGULATOR = 'REGULATOR';
export const RESPONSIBLE = 'RESPONSIBLE';
export const PROMOTER = 'PROMOTER';
export const OFFICIAL = 'OFFICIAL';
export const ATHLETE = 'ATHLETE';
export const COACH = 'COACH';
export const CLUB = 'CLUB';

export const AdminTypes = [SUPER_ADMIN, ADMIN, REGULATOR];

export const MemberTypes = [OFFICIAL, ATHLETE, COACH, PROMOTER, CLUB];

export type AdminListType = {
  _id: string;
  name: string;
};

export const AdminList = [
  { _id: SUPER_ADMIN, name: SUPER_ADMIN },
  { _id: ADMIN, name: ADMIN },
  { _id: REGULATOR, name: REGULATOR },
  { _id: RESPONSIBLE, name: RESPONSIBLE }
];

export type DocumentType = {
  file?: AnyObject;
  expiration_date?: Date | string;
};

type Stats = {
  consistency?: number;
  totalJudged?: number;
  totalRefereed?: number;
  totalCommissioner?: number;
  totalFights?: number;
  needReview?: number;
  eventsScheduled?: number;
  totalWon?: number;
  totalDraw?: number;
  totalLost?: number;
  totalUpcoming?: number;
  totalEventsPending?: number;
  totalEventsValidated?: number;
};

export interface User {
  _id: string;
  name: string;
  email?: string;
  accepted?: boolean;
  status?: typeof INVITED | typeof PENDING | typeof ACTIVE | typeof DEACTIVATED | typeof MEMBER_AWAITING_VALIDATION | typeof MEMBER_REJECTED;
  type?: typeof SUPER_ADMIN | typeof ADMIN | typeof REGULATOR | typeof RESPONSIBLE | typeof PROMOTER | typeof OFFICIAL | typeof ATHLETE | typeof COACH | typeof CLUB;
  admin?: boolean;
  superuser?: boolean;
  phone?: string;
  website?: string;
  birthday?: Date;
  address?: string;
  postal_code?: string;
  city?: string;
  country?: AnyObject;
  federation?: AnyObject;
  club?: AnyObject;
  coach?: AnyObject;
  class?: AnyObject[];
  martial_arts?: AnyObject[];
  roles?: OfficialRoles[];
  judge?: boolean;
  record_keeper?: boolean;
  referee?: boolean;
  commissioner?: boolean;
  record?: string;
  gender?: string;
  nationality?: string;
  certifications?: string;
  info?: string;
  language?: string;
  createdAt?: string;
  updatedAt?: string;
  photo?: AnyObject;
  associate?: string;
  notifications?: {
    new_events?: boolean;
    new_fights?: boolean;
    upcoming_events?: boolean;
    new_fighters_area?: boolean;
    new_updates?: boolean;
    surveys?: boolean;
  };
  documents?: DocumentType[];
  password?: string;
  confirm_password?: string;
  disabled?: boolean;
  weight?: number;
  height?: number;
  profile_link?: string;
  weight_class?: AnyObject;
  identification?: DocumentType[];
  payment?: DocumentType[];
  medical?: DocumentType[];
  clinical?: DocumentType[];
  residence?: DocumentType[];
  others?: DocumentType[];
  certification?: DocumentType[];
  guardian_name?: string;
  guardian_birthday?: Date;
  guardian_address?: string;
  guardian_postal_code?: string;
  guardian_city?: string;
  guardian_phone?: string;
  guardian_identification?: DocumentType[];
  guardian_waiver?: DocumentType[];
  responsible_name?: string;
  responsible_email?: string;
  responsible_phone?: string;
  promoter_creation?: Date;
  nif?: string;
  stats?: Stats;
  group?: string;
  order?: number;
  code?: string;
  allow_manage?: boolean;
}

/* OFFICIAL ROLES */

export const REFEREE = 'REFEREE';
export const JUDGE = 'JUDGE';
export const RECORD_KEEPER = 'RECORD_KEEPER';
export const COMMISSIONER = 'COMMISSIONER';

export type OfficialRoles =
  | typeof REFEREE
  | typeof JUDGE
  | typeof RECORD_KEEPER
  | typeof COMMISSIONER;

export const OfficialRolesList = [
  { _id: REFEREE, name: REFEREE },
  { _id: JUDGE, name: JUDGE },
  { _id: RECORD_KEEPER, name: RECORD_KEEPER },
  { _id: COMMISSIONER, name: COMMISSIONER }
];

/* USER STATUS */

export const INVITED = 'INVITED';
export const PENDING = 'PENDING';
export const ACTIVE = 'ACTIVE';
export const DEACTIVATED = 'DEACTIVATED';

export const UserStatusList = [
  { _id: INVITED, name: INVITED, bgColor: 'rgba(65, 71, 220, 0.2)', fontColor: '#4147DC', showAdmin: true },
  { _id: PENDING, name: PENDING, bgColor: 'rgba(221, 195, 101, 0.1)', fontColor: '#DDC365', showAdmin: false },
  { _id: ACTIVE, name: ACTIVE, bgColor: 'rgba(76, 175, 80, 0.12)', fontColor: '#4CAF50', showAdmin: true },
  { _id: DEACTIVATED, name: DEACTIVATED, bgColor: 'rgba(221, 64, 64, 0.1)', fontColor: '#DD4040', showAdmin: true }
];

/* MEMBERS PENDING */

export const MEMBER_AWAITING_VALIDATION = 'AWAITING_VALIDATION';
export const MEMBER_REJECTED = 'REJECTED';

export const MemberDraftsTypesList = [
  { _id: OFFICIAL, name: OFFICIAL },
  { _id: ATHLETE, name: ATHLETE },
  { _id: COACH, name: COACH },
  { _id: PROMOTER, name: PROMOTER },
  { _id: CLUB, name: CLUB }
];

export const MemberDraftsClubTypesList = [
  { _id: ATHLETE, name: ATHLETE },
  { _id: COACH, name: COACH }
];

/* USER GROUPS */

export const EXTERNAL = 'EXTERNAL';
export const IN_TOURNAMENT = 'IN_TOURNAMENT';
export const FEDERATION = 'FEDERATION';

export type UserGroupType = typeof EXTERNAL | typeof IN_TOURNAMENT | typeof FEDERATION;
