import { SidebarWrapper, OptionsWrapper } from './styles';
import { sidebarOptions, SidebarGroupProps } from './options';
import { ScrollArea } from '@mantine/core';
import { Box, Block } from '../../styles/BasicStyles';
import { ScrollBarThumb, ScrollBarThumbHover, ScrollBarTrack } from '../../styles/Colors';
import { useTranslation } from 'react-i18next';
import { FC, useState, useContext } from 'react';
import { UserContext } from '../../Context';
import { useNavigate } from 'react-router-dom';
import { SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER, OFFICIAL, ATHLETE, COACH, CLUB } from '../../models/User';
import { useLocation } from 'react-router';
import CanBeRendered from '../../utils/user/permissions';
import Group from './components/group';
import DropdownButton, { ActionProps } from '../dropdownButton';
import EventModal from '../../pages/events/manage';
import OfficialModal from '../../pages/members/officials/form';
import AthleteModal from '../../pages/members/athletes/form';
import CoachModal from '../../pages/members/coaches/form';
import PromoterModal from '../../pages/members/promoters/form';
import ClubModal from '../../pages/members/clubs/form';

interface SidebarProps {
  isOpen: boolean;
}

const currentActiveMenu = () => {
  const location = useLocation();
  let active = 0;

  sidebarOptions.forEach((group: SidebarGroupProps) => {
    if(group.options?.length > 0) {
      group.options.forEach((option) => {
        if(option.url && option.number && location.pathname.indexOf(option.url) > -1) {
          active = option.number;
        }
      });
    }
  });

  return active;
}

const Sidebar: FC<SidebarProps> = ({ isOpen }) => {
  const [modal, setModal] = useState<{ event: boolean,  official: boolean,  athlete: boolean, coach: boolean, promoter: boolean, club: boolean }>({ event: false, official: false, athlete: false, coach: false, promoter: false, club: false });
  const [dropdownIsExpanded, setDropdownIsExpanded] = useState<number | undefined>(currentActiveMenu());
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const { user } = useContext(UserContext);

  const handleCloseEventModal = (changed: boolean) => {
    if(changed && user?.type === PROMOTER) navigate('/events/draft');

    closeModal();
  };

  const handleCloseAthleteModal = (changed: boolean) => {
    if(changed) navigate('/members/athlete');

    closeModal();
  };

  const handleCloseOfficialModal = (changed: boolean) => {
    if(changed) navigate('/members/official');

    closeModal();
  };

  const handleCloseCoachModal = (changed: boolean) => {
    if(changed) navigate('/members/coach');

    closeModal();
  };

  const handleClosePromoterModal = (changed: boolean) => {
    if(changed) navigate('/members/promoter');

    closeModal();
  };

  const handleCloseClubModal = (changed: boolean) => {
    if(changed) navigate('/members/club');

    closeModal();
  };

  const closeModal = () => setModal({ event: false, official: false, athlete: false, coach: false, promoter: false, club: false});

  // Officials, Athletes and Coaches don't have the Dropdown menu to create content, so the Sidebar height is different
  let menuHeight = 13.063;
  if(user?.type === OFFICIAL || user?.type === ATHLETE || user?.type === COACH) menuHeight = 8.25;

  const actions: ActionProps[] = [];

  if(user?.type === CLUB) {
    actions.splice(0, 0,
      {
        type: 'link',
        label: t('NEW_ATHLETE'),
        onClick: () => window.open(`${process.env.REACT_APP_WEBAPP_URL}/external/club/${user?.code}/athlete/${user?.federation?.code}`, '_blank')
      },
      {
        type: 'link',
        label: t('NEW_COACH'),
        onClick: () => window.open(`${process.env.REACT_APP_WEBAPP_URL}/external/club/${user?.code}/coach/${user?.federation?.code}`, '_blank')
      }
    );
  }
  else {
    actions.push({
      type: 'link',
      label: t('NEW_EVENT'),
      onClick: () => setModal({ event: true, official: false, athlete: false, coach: false, promoter: false, club: false })
    });

    if(user?.type !== PROMOTER) {
      actions.splice(1, 0,
        {
          type: 'link',
          label: t('NEW_OFFICIAL'),
          onClick: () => setModal({ event: false, official: true, athlete: false, coach: false, promoter: false, club: false })
        },
        {
          type: 'link',
          label: t('NEW_ATHLETE'),
          onClick: () => setModal({ event: false, official: false, athlete: true, coach: false, promoter: false, club: false })
        },
        {
          type: 'link',
          label: t('NEW_COACH'),
          onClick: () => setModal({ event: false, official: false, athlete: false, coach: true, promoter: false, club: false })
        },
        {
          type: 'link',
          label: t('NEW_PROMOTER'),
          onClick: () => setModal({ event: false, official: false, athlete: false, coach: false, promoter: true, club: false })
        },
        {
          type: 'link',
          label: t('NEW_CLUB'),
          onClick: () => setModal({ event: false, official: false, athlete: false, coach: false, promoter: false, club: true })
        }
      );
    }
  }

  return (
    <>
      <SidebarWrapper isOpen={isOpen}>
        <CanBeRendered types={[SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER, CLUB]}>
          <Block mb={1.563}>
            <DropdownButton 
              icon='outlined_plus'
              text={t('ADD_NEW')}
              actions={actions}
            />
          </Block>
        </CanBeRendered>
        {/* Height of the menu 
        * -4.5rem » header
        * -1.875rem * 2 » padding menu
        * -3.25rem » dropdown button height
        * -1.563rem » dropdown button margin
        */}
        {
          sidebarOptions?.length > 0 &&
          <OptionsWrapper>
            <ScrollArea
              type='hover'
              style={{
                width: '100%',
                height: `calc(100vh - ${menuHeight}rem)`
              }}
              scrollbarSize={8}
              scrollHideDelay={200}
              styles={() => ({
                scrollbar: {
                  '&, &:hover': {
                    background: ScrollBarThumbHover
                  },
                  '&[data-orientation="vertical"] .mantine-ScrollArea-thumb': {
                    backgroundColor: ScrollBarThumb,
                    borderRadius: 0,
                  },
                  '&.mantine-ScrollArea-scrollbar': {
                    backgroundColor: ScrollBarTrack,
                    padding: 0
                  }
                }
              })}
            >
              <Box fDirection='column'>
                {sidebarOptions.map((group: SidebarGroupProps) => 
                  <Group 
                    key={group.name} 
                    group={group} 
                    dropdownIsExpanded={dropdownIsExpanded}
                    setDropdownIsExpanded={setDropdownIsExpanded}
                  />
                )}
              </Box>
            </ScrollArea>
          </OptionsWrapper>
        }
      </SidebarWrapper>
      <CanBeRendered types={[SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER]}>
        <EventModal
          opened={modal.event}
          onClose={handleCloseEventModal}
        />
      </CanBeRendered>
      <CanBeRendered types={[SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]}>
        <OfficialModal
          opened={modal.official}
          onClose={handleCloseOfficialModal}
        />
      </CanBeRendered>
      <CanBeRendered types={[SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, CLUB]}>
        <AthleteModal
          opened={modal.athlete}
          onClose={handleCloseAthleteModal}
        />
        <CoachModal
          opened={modal.coach}
          onClose={handleCloseCoachModal}
        />
      </CanBeRendered>
      <CanBeRendered types={[SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]}>
        <PromoterModal
          opened={modal.promoter}
          onClose={handleClosePromoterModal}
        />
        <ClubModal
          opened={modal.club}
          onClose={handleCloseClubModal}
        />
      </CanBeRendered>
    </>
  );
};

export default Sidebar;
