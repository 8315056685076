/* eslint-disable @typescript-eslint/no-explicit-any */
import { LayoutWrapper, LayoutContent } from './LayoutStyles';
import { useClickOutside } from '../../hooks/outside-click';
import { FC, useState, useRef, useContext } from 'react';
import { UserContext } from '../../Context';
import { SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER, OFFICIAL, CLUB } from '../../models/User';
import Sidebar from '../sidebar';
import Header from '../header/Header';

export const Layout: FC<{ children: any, showSidebar?: boolean }> = ({ children, showSidebar = true }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { user } = useContext(UserContext);
  const hasSidebarList = [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER, OFFICIAL, CLUB];

  const closeSidebar = () => {
    if(menuOpen) {
      setMenuOpen(false);
    }
  }

  useClickOutside(containerRef?.current, closeSidebar);

  let sidebarVisible = showSidebar;
  if(user?.type && !hasSidebarList.includes(user.type)) sidebarVisible = false;

  return (
    <>
    <div ref={containerRef}>
      <Header
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        showSidebar={sidebarVisible}
      />
      {
        sidebarVisible &&
        <Sidebar 
          isOpen={menuOpen}
        />
      }
    </div>
    <LayoutWrapper showSidebar={sidebarVisible}>
      <LayoutContent>
        {children}
      </LayoutContent>
    </LayoutWrapper>
    </>
  );
};

export default Layout;
