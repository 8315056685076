/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { PlaceholderColor } from '../../styles/Colors';
import { UserContext } from '../../Context';
import { ClubRequest, ClubRequestStatusList, REJECTED, VALIDATED } from '../../models/ClubRequest';
import { Block } from '../../styles/BasicStyles';
import useFetch from 'use-http';
import DefaultListPage from '../../components/page/DefaultListPage';
import Typography from '../../components/typography';
import BaseTable from '../../components/table';
import MemberDraftsFilters from './ClubRequestFilters';
import UserCard from '../../components/userCard';
import LabelDropdown from '../../components/labelDropdown';
import dayjs from 'dayjs';

const ClubRequestListPage = DefaultListPage<ClubRequest>();
const ClubRequestTable = BaseTable<ClubRequest>();

const ClubRequestPage = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { get } = useFetch(`/member-club-requests/${user?._id}`);
  const { put } = useFetch('/member-club-requests');

  return (
    <ClubRequestListPage
      translate={t}
      request={{
        get,
        searchFields: ['name', 'email', 'associate']
      }}
      storage='MEMBER_CLUB_REQUESTS_FILTERS'
      header={{ title: t('CLUB_REQUESTS') }}
      filters={({ filters, handleChange }) => 
        <MemberDraftsFilters 
          filters={filters} 
          handleChange={handleChange}
        />
      }
      countLabel={`${t('CLUB_REQUESTS').toLowerCase()}`}
    >
      {({ rows, loading, pagination, handleNewSort, refreshPage }) => {
        const onConfirmStatusToogle = async (id: string, status: string) => {
          const { success } = await put(`/${id}/change-status`, { status });
          if(success) refreshPage();
        };

        const columns = [
          {
            title: t('ASSOCIATE_NUMBER'),
            key: 'associate',
            sortable: true,
            render: (_: any, row: ClubRequest) => <Typography variant='table-header'>{row.user?.associate}</Typography>
          },
          { 
            title: t('NAME'), 
            key: 'name', 
            sortable: true,
            render: (_: any, row: ClubRequest) => <UserCard user={row.user} showEmail noStatus />
          },
          {
            title: t('COUNTRY'),
            key: 'country',
            sortable: true,
            render: (_: any, row: ClubRequest) => <Typography variant='table-header'>{row.user?.country?.name}</Typography>
          },
          {
            title: t('STATUS'),
            key: 'status',
            disableRowClick: true,
            align: 'center',
            render: (_: any, row: ClubRequest) => 
              <Block display='flex' fAlign='center' fDirection='column'>
                <LabelDropdown
                  status={row.status}
                  list={ClubRequestStatusList}
                  id={row._id}
                  onConfirmStatusToogle={onConfirmStatusToogle}
                  disabled={row.status === REJECTED || row.status === VALIDATED}
                />
                {
                  !!row.review_date && 
                  <Typography mt={0.25} variant='sidebar-group' style={{ color: PlaceholderColor }} tAlign='center'>
                    {`${t('ON')} ${dayjs(row.review_date).format('DD/MM/YYYY, HH:mm')}h`}
                  </Typography>
                }
              </Block>
          }
        ];

        return (
          <ClubRequestTable
            columns={columns}
            rows={rows}
            loading={loading}
            defaultSort={pagination.sort}
            sortCallback={handleNewSort}
          />
        );
      }}
    </ClubRequestListPage>
  );
};

export default ClubRequestPage;
