import * as yup from 'yup';

export const validations = [
  yup.object({
    name: yup.string().required(),
    birthday: yup.date().required(),
    gender: yup.string(),
    nationality: yup.string(),
    address: yup.string(),
    postal_code: yup.string(),
    city: yup.string(),
    country: yup.string(),
    email: yup.string().email().required(),
    phone: yup.string(),
    federation: yup.string().required(),
    responsible_name: yup.string(),
    responsible_email: yup.string().email(),
    responsible_phone: yup.string()
  }),
  yup.object({
    name: yup.string().required(),
    birthday: yup.date().required(),
    gender: yup.string(),
    nationality: yup.string(),
    address: yup.string(),
    postal_code: yup.string(),
    city: yup.string(),
    country: yup.string(),
    email: yup.string().email().required(),
    phone: yup.string(),
    responsible_name: yup.string(),
    responsible_email: yup.string().email(),
    responsible_phone: yup.string()
  })
];
