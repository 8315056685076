import { User } from "./User";

export interface ClubRequest {
  readonly _id: string;
  club: User;
  user: User;
  status: string;
  review_date?: Date;
}

export const VALIDATED = 'VALIDATED';
export const REJECTED = 'REJECTED';
export const AWAITING_VALIDATION = 'AWAITING_VALIDATION';

export const ClubRequestStatusList = [
  { _id: AWAITING_VALIDATION, name: AWAITING_VALIDATION, bgColor: 'rgba(221, 195, 101, 0.1)', fontColor: '#DDC365' },
  { _id: VALIDATED, name: VALIDATED, bgColor: 'rgba(76, 175, 80, 0.12)', fontColor: '#4CAF50' },
  { _id: REJECTED, name: REJECTED, bgColor: 'rgba(221, 64, 64, 0.1)', fontColor: '#DD4040' }
];