import { Grid } from '@mantine/core';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { ADMIN, ATHLETE, CLUB, REGULATOR, SUPER_ADMIN, User } from '../../../../models/User';
import { AspectRatios } from '../../../../utils/files/Conversions';
import { Federation } from '../../../../models/Federation';
import { FC, useContext, useState, useEffect } from 'react';
import { InfoContext, UserContext } from '../../../../Context';
import { ModalContent } from '../../../../components/modal';
import { AnyObject } from '../../../../models/Generic';
import { Languages } from '../../../../utils/languages/AvailableTranslations';
import TextInput from '../../../../components/inputs/TextInput';
import ImageInput from '../../../../components/inputs/ImageInput';
import DateInput from '../../../../components/inputs/DateInput';
import SelectInput from '../../../../components/inputs/SelectInput';
import PhoneInput from '../../../../components/inputs/PhoneInput';
import MultiSelectInput from '../../../../components/inputs/MultiSelectInput';
import dayjs from 'dayjs';
import Divider from '../../../../components/divider';
import SwitchInput from '../../../../components/inputs/SwitchInput';

interface Params {
  federations: Federation[];
  initialValues?: User;
  clubs: User[];
  resetClub: (...args: AnyObject[]) => void;
  coaches: User[];
  disableFields?: boolean;
}

const FormFields: FC<Params> = ({ federations, initialValues, clubs, resetClub, coaches, disableFields = false }) => {
  const [guardianFields, setGuardianFields] = useState<boolean>(false);
  const { t } = useTranslation();
  const { info } = useContext(InfoContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (initialValues?.birthday) afterBirthdayChange(initialValues.birthday);
  }, [initialValues]);

  const afterBirthdayChange = (value?: Date) => {
    // If the Athlete is under 18, show Guardian fields
    const subtract18 = dayjs().subtract(18, 'year');
    const isAfter = dayjs(value).isAfter(subtract18);

    setGuardianFields(isAfter ? true : false);
  };

  return (
    <ModalContent>
      {
        user?.type === ATHLETE && user?._id === initialValues?._id &&
        <>
          <Grid gutter="xl">
            <Grid.Col xs={12}>
              <Field name="allow_manage">
                {(props) => (
                  <SwitchInput
                    {...props}
                    label={t('ATHLETE_ALLOW_MANAGE')}
                    important
                  />
                )}
              </Field>
            </Grid.Col>
          </Grid>
          <Divider style={{ margin: '1rem 0' }} />
        </>
      }
      <Grid gutter="xl">
        <Grid.Col xs={12}>
          <Field name="photo">
            {(props) => (
              <ImageInput 
                {...props} 
                ratio={AspectRatios['1:1']} 
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="name">
            {(props) => (
              <TextInput
                {...props}
                label={`${t('FULL_NAME')} *`}
                placeholder={t('ENTER_NAME')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <Field name="birthday">
            {(props) => (
              <DateInput
                {...props}
                label={`${t('BIRTH_DATE')} *`}
                placeholder={t('CHOOSE_DATE')}
                afterChange={afterBirthdayChange}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="weight">
            {(props) => (
              <TextInput
                {...props}
                label={`${t('WEIGHT')} (kg)`}
                placeholder={t('ENTER_WEIGHT')}
                htmlType="number"
                min={0}
                step={0.01}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <Field name="height">
            {(props) => (
              <TextInput
                {...props}
                label={`${t('HEIGHT')} (cm)`}
                placeholder={t('ENTER_HEIGHT')}
                htmlType="number"
                min={0}
                step={0.1}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="gender">
            {(props) => (
              <SelectInput
                {...props}
                label={t('GENDER')}
                placeholder={t('CHOOSE_GENDER')}
                clearable
                data={info?.genders || []}
                translate
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <Field name="nationality">
            {(props) => (
              <TextInput
                {...props}
                label={t('NATIONALITY')}
                placeholder={t('ENTER_NATIONALITY')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="address">
            {(props) => (
              <TextInput
                {...props}
                label={t('ADDRESS')}
                placeholder={t('ENTER_ADDRESS')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <Field name="postal_code">
            {(props) => (
              <TextInput
                {...props}
                label={t('POSTAL_CODE')}
                placeholder={t('ENTER_POSTAL_CODE')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="city">
            {(props) => (
              <TextInput
                {...props}
                label={t('CITY')}
                placeholder={t('ENTER_CITY')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <Field name="country">
            {(props) => (
              <SelectInput
                {...props}
                label={t('COUNTRY')}
                placeholder={t('CHOOSE_COUNTRY')}
                clearable
                data={info?.countries || []}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="email">
            {(props) => (
              <TextInput
                {...props}
                label={`${t('EMAIL')} *`}
                htmlType="email"
                placeholder={t('ENTER_EMAIL')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <Field name="phone">
            {(props) => (
              <PhoneInput 
                {...props} 
                label={t('PHONE_NUMBER')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        {(user?.type === SUPER_ADMIN ||
          user?.type === ADMIN ||
          user?.type === REGULATOR) && (
          <Grid.Col xs={12} sm={6}>
            <Field name="federation">
              {(props) => (
                <SelectInput
                  {...props}
                  label={`${t('FEDERATION')} *`}
                  placeholder={t('CHOOSE_FEDERATION')}
                  clearable
                  data={federations}
                  afterChange={() => resetClub()}
                  disabled={disableFields}
                />
              )}
            </Field>
          </Grid.Col>
        )}
        {
          user?.type !== CLUB && 
          <Grid.Col xs={12} sm={6}>
            <Field name="club">
              {(props) => (
                <SelectInput
                  {...props}
                  label={t('CLUB')}
                  placeholder={t('CHOOSE_CLUB')}
                  clearable
                  data={clubs}
                  disabled={disableFields}
                />
              )}
            </Field>
          </Grid.Col>
        }
        <Grid.Col xs={12} sm={6}>
          <Field name="coach">
            {(props) => (
              <SelectInput
                {...props}
                label={t('COACH')}
                placeholder={t('CHOOSE_COACH')}
                clearable
                data={coaches}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <Field name="weight_class">
            {(props) => (
              <SelectInput
                {...props}
                label={t('WEIGHT_CLASS')}
                placeholder={t('CHOOSE_CLASS')}
                clearable
                intl
                data={info?.weightClasses || []}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="record">
            {(props) => (
              <TextInput
                {...props}
                label={t('RECORD_WDL')}
                placeholder={t('RECORD_WDL')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <Field name="class">
            {(props) => (
              <MultiSelectInput
                {...props}
                label={t('RATING_CLASS')}
                placeholder={t('CHOOSE_CLASS')}
                clearable
                data={info?.evaluationClasses || []}
                intl
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12}>
          <Field name="martial_arts">
            {(props) => (
              <MultiSelectInput
                {...props}
                label={t('MARTIAL_ARTS')}
                placeholder={t('CHOOSE_MARTIALS_ARTS')}
                clearable
                data={info?.martialArts || []}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>

      <Grid gutter="xl">
        <Grid.Col xs={12}>
          <Field name="profile_link">
            {(props) => (
              <TextInput
                {...props}
                label={t('PROFILE_RECORD_LINK')}
                placeholder={t('ENTER_LINK')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      {guardianFields && (
        <>
          <Grid gutter="xl">
            <Grid.Col xs={12} sm={6}>
              <Field name="guardian_name">
                {(props) => (
                  <TextInput
                    {...props}
                    label={t('GUARDIAN_NAME')}
                    placeholder={t('ENTER_NAME')}
                    disabled={disableFields}
                  />
                )}
              </Field>
            </Grid.Col>
            <Grid.Col xs={12} sm={6}>
              <Field name="guardian_birthday">
                {(props) => (
                  <DateInput
                    {...props}
                    label={t('GUARDIAN_BIRTH_DATE')}
                    placeholder={t('CHOOSE_DATE')}
                    disabled={disableFields}
                  />
                )}
              </Field>
            </Grid.Col>
          </Grid>
          <Grid gutter="xl">
            <Grid.Col xs={12} sm={6}>
              <Field name="guardian_address">
                {(props) => (
                  <TextInput
                    {...props}
                    label={t('GUARDIAN_ADDRESS')}
                    placeholder={t('ENTER_ADDRESS')}
                    disabled={disableFields}
                  />
                )}
              </Field>
            </Grid.Col>
            <Grid.Col xs={12} sm={6}>
              <Field name="guardian_postal_code">
                {(props) => (
                  <TextInput
                    {...props}
                    label={t('GUARDIAN_POSTAL_CODE')}
                    placeholder={t('ENTER_POSTAL_CODE')}
                    disabled={disableFields}
                  />
                )}
              </Field>
            </Grid.Col>
          </Grid>
          <Grid gutter="xl">
            <Grid.Col xs={12} sm={6}>
              <Field name="guardian_city">
                {(props) => (
                  <TextInput
                    {...props}
                    label={t('GUARDIAN_CITY')}
                    placeholder={t('ENTER_CITY')}
                    disabled={disableFields}
                  />
                )}
              </Field>
            </Grid.Col>
            <Grid.Col xs={12} sm={6}>
              <Field name="guardian_phone">
                {(props) => (
                  <PhoneInput 
                    {...props} 
                    label={t('GUARDIAN_PHONE_NUMBER')} 
                    disabled={disableFields}
                  />
                )}
              </Field>
            </Grid.Col>
          </Grid>
        </>
      )}
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="language">
            {(props) => (
              <SelectInput
                {...props}
                label={t('LANGUAGE')}
                placeholder={t('CHOOSE_LANGUAGE')}
                clearable
                data={Languages || []}
                keyValue='code'
                translate
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
    </ModalContent>
  );
};

export default FormFields;
