import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { User } from '../../../../../models/User';
import { AnyObject } from '../../../../../models/Generic';
import { addItem } from '../../../../../utils/user';
import Typography from '../../../../../components/typography';
import InfoCard, { ListItem } from '../../../common/InfoCard';
import dayjs from 'dayjs';

interface Params {
  club?: User;
  isDraftMember: boolean;
}

const InfoCards: FC<Params> = ({ club, isDraftMember }) => {
  const { t } = useTranslation();

  let martialArts = '';
  if(club?.martial_arts && club.martial_arts.length > 0) {
    club.martial_arts.forEach((martialArt: AnyObject) => martialArts = `${martialArts}${martialArt.name}, `);

    martialArts = martialArts.slice(0, -2); 
  }

  let address = '';
  if(club?.address) address = club.address;
  if(club?.postal_code) address = address ? `${address} ${club.postal_code}` : club.postal_code;
  if(club?.city) address = address ? `${address}, ${club.city}` : club.city;

  const generalItems: ListItem[] = [];
  addItem(generalItems, t('FOUNDATION_DATE'), club?.birthday ? dayjs(club?.birthday).format('DD MMM, YYYY') : '');
  addItem(generalItems, t('EMAIL'), club?.email);
  addItem(generalItems, t('PHONE_NUMBER'), club?.phone);
  addItem(generalItems, t('WEBSITE'), club?.website);
  addItem(generalItems, t('ADDRESS'), address);
  addItem(generalItems, t('COUNTRY'), club?.country?.name);
  addItem(generalItems, t('MARTIAL_ARTS_BACKGROUND'), martialArts);

  const managerItems: ListItem[] = [];
  addItem(managerItems, t('NAME'), club?.responsible_name);
  addItem(managerItems, t('EMAIL'), club?.responsible_email);
  addItem(managerItems, t('PHONE_NUMBER'), club?.responsible_phone);
  
  const federationItems: ListItem[] = [];
  addItem(federationItems, t('FEDERATION'), club?.federation?.name);

  if(!isDraftMember) {
    addItem(federationItems, t('AFFILIATION_NUMBER'), club?.associate, 1);
  }

  // Mark last items as "last" to hide the border
  if(generalItems.length > 0) generalItems[generalItems.length - 1] = { ...generalItems[generalItems.length - 1], last: true };
  if(federationItems.length > 0) federationItems[federationItems.length - 1] = { ...federationItems[federationItems.length - 1], last: true };

  return (
    <>
      {
        generalItems.length > 0 &&
        <InfoCard 
          items={generalItems}
        />
      }
      {
        managerItems.length > 0 &&
        <>
          <Typography variant='body-small' color='#ffffff' pb={0.688} pt={1.5}>{t('CLUB_MANAGER_INFO').toUpperCase()}</Typography>
          <InfoCard 
            items={managerItems}
          />
        </>
      }
      {
        federationItems.length > 0 &&
        <>
          <Typography variant='body-small' color='#ffffff' pb={0.688} pt={1.5}>{t('FEDERATION_INFO').toUpperCase()}</Typography>
          <InfoCard 
            items={federationItems}
          />
        </>
      }
    </>
  );
};

export default InfoCards;
