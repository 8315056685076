import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { User } from '../../../../../models/User';
import { AnyObject } from '../../../../../models/Generic';
import { addItem } from '../../../../../utils/user';
import Typography from '../../../../../components/typography';
import InfoCard, { ListItem } from '../../../common/InfoCard';
import dayjs from 'dayjs';

interface Params {
  coach?: User;
  isDraftMember: boolean;
}

const InfoCards: FC<Params> = ({ coach, isDraftMember }) => {
  const { t, i18n: { language } } = useTranslation();

  let martialArts = '';
  if(coach?.martial_arts && coach.martial_arts.length > 0) {
    coach.martial_arts.forEach((martialArt: AnyObject) => martialArts = `${martialArts}${martialArt.name}, `);

    martialArts = martialArts.slice(0, -2); 
  }

  let evaluationClasses = '';
  if(coach?.class && coach.class.length > 0) {
    coach.class.forEach((elem: AnyObject) => evaluationClasses = `${evaluationClasses}${elem.name[language]}, `);

    evaluationClasses = evaluationClasses.slice(0, -2); 
  }

  let address = '';
  if(coach?.address) address = coach.address;
  if(coach?.postal_code) address = address ? `${address} ${coach.postal_code}` : coach.postal_code;
  if(coach?.city) address = address ? `${address}, ${coach.city}` : coach.city;

  const generalItems: ListItem[] = [];
  addItem(generalItems, t('BIRTH_DATE'), coach?.birthday ? dayjs(coach?.birthday).format('DD MMM, YYYY') : '');
  addItem(generalItems, t('ADDRESS'), address);
  addItem(generalItems, t('COUNTRY'), coach?.country?.name);
  addItem(generalItems, t('GENDER'), t(coach?.gender || ''));
  addItem(generalItems, t('NATIONALITY'), coach?.nationality);
  addItem(generalItems, t('EMAIL'), coach?.email);
  addItem(generalItems, t('PHONE_NUMBER'), coach?.phone);
  addItem(generalItems, t('CLUB'), coach?.club?.name);

  const careerItems: ListItem[] = [];
  addItem(careerItems, t('CLASS'), evaluationClasses);
  addItem(careerItems, t('MARTIAL_ARTS_BACKGROUND'), martialArts);

  const federationItems: ListItem[] = [];
  addItem(federationItems, t('FEDERATION'), coach?.federation?.name);

  if(!isDraftMember) {
    addItem(federationItems, t('AFFILIATION_NUMBER'), coach?.associate);
  }

  // Mark last items as "last" to hide the border
  if(generalItems.length > 0) generalItems[generalItems.length - 1] = { ...generalItems[generalItems.length - 1], last: true };
  if(careerItems.length > 0) careerItems[careerItems.length - 1] = { ...careerItems[careerItems.length - 1], last: true };
  if(federationItems.length > 0) federationItems[federationItems.length - 1] = { ...federationItems[federationItems.length - 1], last: true };
  
  return (
    <>
      {
        generalItems.length > 0 &&
        <InfoCard 
          items={generalItems}
        />
      }
      {
        careerItems.length > 0 &&
        <>
          <Typography variant='body-small' color='#ffffff' pb={0.688} pt={1.5}>{t('CAREER_INFO').toUpperCase()}</Typography>
          <InfoCard 
            items={careerItems}
          />
        </>
      }
      {
        federationItems.length > 0 &&
        <>
          <Typography variant='body-small' color='#ffffff' pb={0.688} pt={1.5}>{t('FEDERATION_INFO').toUpperCase()}</Typography>
          <InfoCard 
            items={federationItems}
          />
        </>
      }
    </>
  );
};

export default InfoCards;
