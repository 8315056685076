/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, Fragment, useEffect, useState } from 'react';
import { Block, Box } from '../../styles/BasicStyles';
import { Column, ColumnWrapper, FighterWrapper, FighterContent, BracketWrapper, EditFighterContent, FightInfo } from './styles';
import { TournamentRound, TournamentFight, Tournament, SelectedFighters } from '../../models/Tournament';
import { useTranslation } from 'react-i18next';
import { FiltersBgColor, FocusColor, SubMenuColor, SuccessColor } from '../../styles/Colors';
import { CanEditFighter, DraftRandomFighter, GetRoundName, HasResults } from '../../utils/tournament/single';
import { showConfirm, showSuccess } from '../../hooks/show-notification/show-notification';
import { User } from '../../models/User';
import { useParams } from 'react-router-dom';
import { GetFightDate } from '../../utils/fights';
import UserCard from '../../components/userCard';
import Icon from '../../components/icon';
import Typography from '../../components/typography';
import Button from '../../components/button';
import SelectInput from '../../components/inputs/SelectInput';
import UserItem from '../../components/userDropdownItem';
import useFetch from 'use-http';

interface DropdownStyle {
  id: string;
  top: number;
  left: number;
}

interface Params {
  tournament?: Tournament;
  bracket?: TournamentRound[]; 
  athletes: User[];
  reloadInfo: () => void;
  checkAthlete: (value: string | null, oldValue: string | null, fight?: string, type?: 'red_fighter' | 'blue_fighter') => void;
  selectedFighters: SelectedFighters[];
  setSelectedFighters: (value: SelectedFighters[]) => void;
  loadAthletes: () => void;
}

const BracketComponent: FC<Params> = ({ bracket, athletes, reloadInfo, checkAthlete, tournament, selectedFighters, setSelectedFighters, loadAthletes }) => {
  const [scale, setScale] = useState<number>(1);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [dropdownStyle, setDropdownStyle] = useState<DropdownStyle[]>([]);
  const hasResults = HasResults(bracket);
  const { t } = useTranslation();
  const { id } = useParams();
  const saveHook = useFetch('/tournaments');

  useEffect(() => {
    if(bracket && bracket?.length > 0) {
      const aux: SelectedFighters[] = [];

      bracket.forEach((round: TournamentRound) => {
        if(round.fights.length > 0) {
          round.fights.forEach((fight: TournamentFight) => {
            if(fight.fight?.red_fighter?._id || fight.fight?.blue_fighter?._id) {
              aux.push({
                bracket: fight.bracket,
                fight: fight.fight?._id,
                red_fighter: fight.fight?.red_fighter?._id || null,
                blue_fighter: fight.fight?.blue_fighter?._id || null
              });
            }
          });
        }
      });

      setSelectedFighters(aux);
    }
  }, [bracket]);

   // Show confirmation popup if we're trying to save before selecting fighters for all fights
  const beforeSubmit = () => {
    let missingFighters = 0;
    const firstFights = tournament?.fights?.filter(elem => !elem.bye) || [];

    if(firstFights.length > 0) {
      firstFights.forEach(fight => {
        const aux = selectedFighters.find(elem => elem.fight === fight.fight?._id);

        if(!fight.last_games) {
          if(!!aux) {
            if(!aux?.red_fighter) missingFighters++;
            if(!aux?.blue_fighter) missingFighters++;
          }
          else {
            missingFighters += 2;
          }
        }
        else if(fight.last_games?.length === 2) {
          const lastGame1 = fight.last_games[0];
          const lastGame2 = fight.last_games[1];

          const lastGame1Detail = tournament?.fights?.find(elem => elem.bracket === lastGame1);
          const lastGame2Detail = tournament?.fights?.find(elem => elem.bracket === lastGame2);

          if(lastGame1Detail?.bye || lastGame2Detail?.bye) {
            if(!!aux) {
              if(!aux?.red_fighter && lastGame1Detail?.bye) missingFighters++;
              if(!aux?.blue_fighter && lastGame2Detail?.bye) missingFighters++;
            }
            else {
              missingFighters++;
            }
          }
        }
      });
    }

    if(missingFighters === 0) onSubmit();
    else {
      showConfirm({
        title: t('SAVE_FIGHTERS'),
        message: t('SAVE_FIGHTERS_MESSAGE'),
        onConfirm: onSubmit
      });
    }
  };

  const onSubmit = async () => {
    const { success } = await saveHook.put(`/${id}/save-fighters`, { fights: selectedFighters });
    if(success) {
      showSuccess({
        title: t('SUCCESS'),
        message: t('TOURNAMENT_EDITED_MESSAGE')
      })

      setEditMode(false);
      reloadInfo();
    }
  };

  const assignFighters = () => {
    showConfirm({
      title: t('ASSIGN_FIGHTERS'),
      message: t('ASSIGN_FIGHTERS_MESSAGE'),
      onConfirm: async () => {
        // Selected fighters in the tournament (mark all as undrafted)
        const tournamentFighters = tournament?.fighters?.map(elem => { return { _id: elem._id.toString(), drafted: false }; }) || [];

        // Mark the Fighters that already have a Fight as drafted
        if(selectedFighters.length > 0) {
          selectedFighters.forEach((fighter: SelectedFighters) => {
            if(fighter.red_fighter) {
              const index = tournamentFighters.findIndex(elem => elem._id === fighter.red_fighter);
              if(index >= 0) tournamentFighters[index].drafted = true;
            }
            if(fighter.blue_fighter) {
              const index = tournamentFighters.findIndex(elem => elem._id === fighter.blue_fighter);
              if(index >= 0) tournamentFighters[index].drafted = true;
            }
          });
        }

        // Check if there are still available fighters to be drafted
        const notDrafted = tournamentFighters.filter(elem => !elem.drafted);

        // Assign random fighters to missing slots in fights
        if(notDrafted.length > 0 && tournament?.fights && tournament?.fights?.length > 0) {
          const aux = [...selectedFighters];

          tournament.fights.forEach((fight: any) => {
            if(!fight.bye) {
              const index = aux.findIndex(elem => elem.bracket === fight.bracket);
              const canEditRed = CanEditFighter(bracket, fight, 'red_fighter');
              const canEditBlue = CanEditFighter(bracket, fight, 'blue_fighter');

              const canAssignRed = canEditRed && (!aux[index] || (!!aux[index] && !aux[index].red_fighter));
              const canAssignBlue = canEditBlue && (!aux[index] || (!!aux[index] && !aux[index].blue_fighter));

              if(canAssignRed || canAssignBlue) {
                let newElem: SelectedFighters = {
                  bracket: fight.bracket,
                  fight: fight.fight._id
                };

                if(canAssignRed) {
                  const randomFighter = DraftRandomFighter(tournamentFighters);
                  newElem = { ...newElem, red_fighter: randomFighter };

                  // Disabled fighter in available fighters list
                  checkAthlete(randomFighter, null);
                }

                if(canAssignBlue) {
                  const randomFighter = DraftRandomFighter(tournamentFighters);
                  newElem = { ...newElem, blue_fighter: randomFighter };
                  
                  // Disabled fighter in available fighters list
                  checkAthlete(randomFighter, null);
                }
                
                if(index >= 0) aux[index] = { ...aux[index], ...newElem };
                else aux.push(newElem);
              }
            }
          });

          setSelectedFighters(aux);
        }
      }
    });
  };

  const scaleBracket = (type: 'up' | 'down') => {
    let aux = scale;

    if((type === 'down' && aux <= 0.5) || (type === 'up' && aux >= 1)) return;

    if(type === 'up') aux += 0.1;
    if(type === 'down') aux -= 0.1;
    setScale(Math.round(aux * 10) / 10);
  };

  const saveFighter = (value: string | null, fight: TournamentFight, type?: 'red_fighter' | 'blue_fighter') => {
    const aux = [...selectedFighters];

    let newElem: SelectedFighters = {
      bracket: fight.bracket,
      fight: fight.fight._id
    };

    if(type === 'red_fighter') newElem = { ...newElem, red_fighter: value };
    else if(type === 'blue_fighter') newElem = { ...newElem, blue_fighter: value };

    const index = aux.findIndex(elem => elem.bracket === fight.bracket);
    if(index >= 0) aux[index] = { ...aux[index], ...newElem };
    else aux.push(newElem);

    setSelectedFighters(aux);
  };

  // Enable/Disable fighter in the list
  const checkFighter = (value: string | null, oldValue: string | null, fight: string, type: 'red_fighter' | 'blue_fighter') => {
    if(value) checkAthlete(value, oldValue, fight, type);
    else checkAthlete(null, oldValue, fight, type);
  }

  const getFighter = (fight: TournamentFight, type: 'red_fighter' | 'blue_fighter') => {
    const fighter = selectedFighters.find(elem => elem.fight === fight.fight?._id);

    if(fighter) return fighter[type] || '';
    return '';
  };

  // Because of the overflow in the div, we need to calculate the dropdown position each time we open it
  const onDropdownOpen = (id: string) => {
    const dropdown = document.getElementById(id);
    const rect = dropdown?.getBoundingClientRect();

    const aux = [...dropdownStyle];

    const index = aux.findIndex(elem => elem.id === id);
    if(index >= 0) {
      aux[index].top = rect?.top || 0;
      aux[index].left = rect?.left || 0;
    }
    else {
      aux.push({
        id,
        top: rect?.top || 0,
        left: rect?.left || 0
      });
    }

    setDropdownStyle(aux);
  }

  const renderFighter = (fight: TournamentFight, type: 'red_fighter' | 'blue_fighter', showFightInfo?: boolean) => {
    const allowEdit = CanEditFighter(bracket, fight, type);
    const id = `dropdown_${fight.fight?._id}`;

    let fightInfo = `#${fight.fight?.fight_number}`;
    if(fight.fight?.start_datetime) fightInfo = `${fightInfo} - ${GetFightDate(fight.fight?.start_datetime)}`;

    return (
      <FighterWrapper isBye={false}>
          {
            editMode && allowEdit ?
            <EditFighterContent 
              top={dropdownStyle.find(elem => elem.id === id)?.top || 0}
              left={dropdownStyle.find(elem => elem.id === id)?.left || 0}
            >
              <SelectInput
                meta={{}}
                input={{ 
                  value: getFighter(fight, type), 
                  onChange: (value: string | null) => saveFighter(value, fight, type)
                }}
                placeholder={t('CHOOSE_FIGHTER')}
                data={athletes}
                itemComponent={UserItem}
                clearable
                afterChange={(value: string | null, oldValue: string | null) => checkFighter(value, oldValue, fight.fight?._id, type)}
                onDropdownOpen={() => onDropdownOpen(id)}
                id={id}
              />
            </EditFighterContent>
            :
            <FighterContent hasFighter={!!fight.fight && !!fight.fight[type]}>
              <UserCard user={fight.fight ? fight.fight[type] : {}} size='xs' noUserText={allowEdit ? t('ASSIGN_FIGHTER') : ''}/>
            </FighterContent>
          }
          {
            showFightInfo &&
            <FightInfo>
              <Typography as='div' variant='sidebar-group' fSize={0.813} style={{ color: 'rgba(255, 255, 255, 0.8)' }}>{fightInfo}</Typography>
            </FightInfo>
          }
      </FighterWrapper>
    );
  };

  const renderWinner = (fight: TournamentFight) => {
    return (
      <FighterWrapper isBye={false}>
        <FighterContent hasFighter={true}>
          <UserCard user={fight.winner || {}} size='xs' />
        </FighterContent>
      </FighterWrapper>
    );
  };

  const renderBye = () => {
    return (
      <FighterWrapper isBye={true}>
        <FighterContent hasFighter={false} />
      </FighterWrapper>
    );
  };

  const cancelEdit = () => {
    loadAthletes();
    setSelectedFighters([]); 
    setEditMode(false);
  };

  return (
    <>
      <Box fJustify='space-between' margin='1.5 0'>
        <Block display='flex' fAlign='center'>
          {
            editMode ?
            <>
              <Button
                text={t('CANCEL')}
                icon="outlined_ban"
                variant='primary'
                onClick={cancelEdit}
                size='xs'
                mr={0.5}
                disabled={saveHook.loading}
              />
              <Button
                text={t('ASSIGN_FIGHTERS')}
                icon="outlined_refresh"
                variant={FocusColor}
                onClick={assignFighters}
                size='xs'
                mr={0.5}
                disabled={saveHook.loading}
              />
              <Button
                text={t('SAVE')}
                icon="outlined_checkCircle"
                variant={SuccessColor}
                onClick={beforeSubmit}
                size='xs'
                disabled={saveHook.loading}
                loading={saveHook.loading}
              />
            </>
            :
            !hasResults ?
            <Button
              text={t('EDIT_BRACKET')}
              icon="outlined_pencil"
              variant='gradient'
              onClick={() => { if(scale !== 1) setScale(1); setEditMode(true); }}
              size='xs'
            />
            :
            null
          }
        </Block>
        {
          !editMode &&
          <Block w={8} display='flex' fJustify='space-between' fAlign='center' bgColor={FiltersBgColor} bRadius={0.374} padding='0.25 0.75'>
            <Icon 
              icon='outlined_zoomOut' 
              size={1.5} 
              hoverColor={FocusColor} 
              onClick={() => scaleBracket('down')}
            />
            <Typography variant='body-small' style={{ color: SubMenuColor }}>{scale * 100}%</Typography>
            <Icon 
              icon='outlined_zoomIn' 
              size={1.5} 
              hoverColor={FocusColor} 
              onClick={() => scaleBracket('up')} 
            />
          </Block>
        }
      </Box>
      {
        bracket && bracket.length > 0 &&
        <BracketWrapper editMode={editMode} scale={scale}>
          <Block display='flex' fDirection='row'>
            {
              bracket.map((round: TournamentRound, index: number) =>
                <Column key={index}>
                  <Typography variant='body-small' tAlign='center'>{GetRoundName(t, round.round, bracket)}</Typography>
                  <ColumnWrapper>
                    {
                      round?.fights?.length > 0 &&
                      round.fights.map((fight: TournamentFight, fightIndex: number) =>
                        <Fragment key={`${index}_${fightIndex}`}>
                          {
                            fight.bye ?
                            <>
                              {renderBye()}
                              {renderBye()}
                            </>
                            :
                            !!fight.winner ?
                            renderWinner(fight)
                            :
                            <>
                              {renderFighter(fight, 'red_fighter', true)}
                              {renderFighter(fight, 'blue_fighter')}
                            </>
                          }
                        </Fragment>
                      )
                    }
                  </ColumnWrapper>
                </Column>
              )
            }
          </Block>
        </BracketWrapper>
      }
    </>
  );
};

export default BracketComponent;
