import {
  User,
  SUPER_ADMIN,
  ADMIN,
  REGULATOR,
  ATHLETE,
  UserStatusList,
  PROMOTER,
  INVITED,
  COACH,
  RESPONSIBLE,
  CLUB
} from '../../../models/User';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext } from 'react';
import { LabelColor } from '../../../styles/Colors';
import {
  showConfirm,
  showDelete,
  showSuccess
} from '../../../hooks/show-notification/show-notification';
import { Federation } from '../../../models/Federation';
import { AnyObject } from '../../../models/Generic';
import { UserContext } from '../../../Context';
import { useNavigate } from 'react-router-dom';
import { GetAthleteInitialValues } from '../../../utils/modalValues';
import { ButtonProps } from '../../../components/button';
import useFetch from 'use-http';
import DefaultListPage from '../../../components/page/DefaultListPage';
import Icon from '../../../components/icon';
import Typography from '../../../components/typography';
import ManageAthleteModal from './form';
import DropdownMenu, { ActionProps } from '../../../components/dropdownMenu';
import BaseTable from '../../../components/table';
import AthletesFilters from './AthletesFilters';
import UserCard from '../../../components/userCard';
import LabelDropdown from '../../../components/labelDropdown';

const AthletesListPage = DefaultListPage<User>();
const AthletesTable = BaseTable<User>();

const AthletesPage = () => {
  const [federations, setFederations] = useState<Federation[]>([]);
  const [coaches, setCoaches] = useState<User[]>([]);
  const [clubs, setClubs] = useState<User[]>([]);
  const [modal, setModal] = useState<{ open: boolean; initialValues?: User }>({ open: false });
  const { t } = useTranslation();
  const { get } = useFetch(`/members/${ATHLETE}`);
  const membersHook = useFetch('/members');
  const federationsHook = useFetch('/federations/dropdown');
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      const federationsResult = await federationsHook.get();
      setFederations(federationsResult?.data || []);
    };

    const init2 = async () => {
      const coachesResult = await membersHook.get(`${COACH}/dropdown`);
      setCoaches(coachesResult?.data || []);

      if(user?.type !== CLUB) {
        const clubsResult = await membersHook.get(`${CLUB}/dropdown`);
        setClubs(clubsResult?.data || []);
      }
    };

    if(user?.type === SUPER_ADMIN || user?.type === ADMIN || user?.type === REGULATOR) init();

    if(user?.type === SUPER_ADMIN || user?.type === ADMIN || user?.type === REGULATOR || user?.type === RESPONSIBLE) init2();
  }, []);

  let actions: ButtonProps[] = [];
  if (user?.type !== PROMOTER)
    actions = [
      {
        text: t('ADD_NEW'),
        icon: 'outlined_plus',
        onClick: () => user?.type === CLUB ? window.open(`${process.env.REACT_APP_WEBAPP_URL}/external/club/${user?.code}/athlete/${user?.federation?.code}`, '_blank') : setModal({ open: true })
      }
    ];

  return (
    <AthletesListPage
      translate={t}
      request={{
        get,
        searchFields: ['name', 'email', 'associate']
      }}
      storage="ATHLETES_FILTERS"
      header={{
        title: t('ATHLETES'),
        actions
      }}
      filters={({ filters, handleChange }) => (
        <AthletesFilters
          filters={filters}
          handleChange={handleChange}
          federations={federations}
          coaches={coaches}
          clubs={clubs}
        />
      )}
      countLabel={`${t('ATHLETES').toLowerCase()}`}
    >
      {({ rows, loading, pagination, handleNewSort, refreshPage }) => {
        const handleCloseModal = (changed: boolean) => {
          if (changed) refreshPage();
          setModal({ open: false });
        };

        const handleDelete = async (id: string) => {
          showDelete({
            title: t('DELETE_MEMBER'),
            message: t('DELETE_MEMBER_MESSAGE'),
            onConfirm: async () => {
              await membersHook.del(`/${id}`);
              refreshPage();
            }
          });
        };

        const resendInvite = async (id: string) => {
          showConfirm({
            title: t('RESEND_INVITE'),
            message: t('RESEND_INVITE_MESSAGE'),
            onConfirm: async () => {
              const { success } = await membersHook.get(`/resend-invite/${id}`);

              if (success) {
                showSuccess({
                  title: t('USER_INVITED_SUCCESS'),
                  message: t('USER_INVITED_MESSAGE')
                });

                refreshPage();
              }
            }
          });
        };

        const onConfirmStatusToogle = async (id: string, status: string) => {
          const { success } = await membersHook.put(`/${id}/change-status`, { status });
          if(success) refreshPage();
        };

        const columns = [
          {
            title: t('ASSOCIATE_NUMBER'),
            dataIndex: 'associate',
            sortable: true
          },
          {
            title: t('NAME'),
            dataIndex: 'name',
            sortable: true,
            render: (_: string, athlete: User) => (
              <UserCard user={athlete} noStatus />
            )
          },
          {
            title: t('COUNTRY'),
            dataIndex: 'country',
            sortable: true,
            render: (country: AnyObject) => (
              <Typography variant="table-header">{country?.name}</Typography>
            )
          },
          {
            title: `${t('WEIGHT')} (kg)`,
            dataIndex: 'weight',
            sortable: true
          },
          {
            title: t('RECORD'),
            subtitle: t('W_L_D'),
            dataIndex: 'record',
            render: (value: string) => (
              <Typography variant="table-header">{value || '0-0-0'}</Typography>
            )
          },
          {
            title: t('STATUS'),
            dataIndex: 'status',
            disableRowClick: true,
            render: (value: string, athlete: User) => (
              <LabelDropdown
                status={value}
                list={UserStatusList}
                id={athlete._id}
                onConfirmStatusToogle={onConfirmStatusToogle}
                disabled={user?.type === PROMOTER || user?.type === CLUB}
              />
            )
          },
          {
            title: t('MORE'),
            key: 'actions',
            disableRowClick: true,
            render: (athlete: User) => {
              const options: ActionProps[] = [
                {
                  type: 'link',
                  label: t('VIEW_DETAIL'),
                  onClick: () => navigate(`/members/athlete/${athlete._id}`)
                }
              ];

              let spliceIndex = 1;
              if(athlete.status === INVITED) {
                options.splice(
                  1,
                  0,
                  {
                    type: 'divider'
                  },
                  {
                    type: 'link',
                    label: t('RESEND_INVITE'),
                    onClick: () => resendInvite(athlete._id)
                  }
                );

                spliceIndex += 2;
              }

              if(user?.type !== PROMOTER) {
                // If the current User is a Club or Coach, and they are seeing their Athletes list, they can only edit their profile if the Athlete allowed it
                if(
                  (user?.type !== CLUB && user?.type !== COACH) ||
                  ((user?.type === CLUB || user?.type === COACH) && athlete?.type === ATHLETE && athlete?.allow_manage)
                ) {
                  options.splice(
                    spliceIndex,
                    0,
                    {
                      type: 'divider'
                    },
                    {
                      type: 'link',
                      label: t('EDIT'),
                      onClick: () =>
                        setModal({
                          open: true,
                          initialValues: GetAthleteInitialValues(athlete)
                        })
                    }
                  );

                  spliceIndex += 2;
                }

                options.splice(
                  spliceIndex,
                  0,
                  {
                    type: 'divider'
                  },
                  {
                    type: 'link',
                    label: t('DELETE'),
                    onClick: () => handleDelete(athlete._id)
                  }
                );
              }

              return (
                <DropdownMenu
                  render={
                    <Icon icon="outlined_dotsHorizontal" color={LabelColor} />
                  }
                  menuPosition="bottom-end"
                  actions={options}
                />
              );
            }
          }
        ];

        // Show Federation column for Admin users
        if (
          user?.type === SUPER_ADMIN ||
          user?.type === ADMIN ||
          user?.type === REGULATOR
        ) {
          columns.splice(4, 0, {
            title: t('FEDERATION'),
            dataIndex: 'federation',
            sortable: true,
            render: (_: string, athlete: User) => (
              <Typography variant="table-header">
                {athlete.federation?.name}
              </Typography>
            )
          });
        }

        return (
          <>
            <AthletesTable
              columns={columns}
              rows={rows}
              loading={loading}
              defaultSort={pagination.sort}
              sortCallback={handleNewSort}
              onRowClick={(row) => navigate(`/members/athlete/${row._id}`)}
            />
            {user?.type !== PROMOTER && (
              <ManageAthleteModal
                opened={modal.open}
                initialValues={modal.initialValues}
                onClose={handleCloseModal}
              />
            )}
          </>
        );
      }}
    </AthletesListPage>
  );
};

export default AthletesPage;
