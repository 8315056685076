/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fight } from "../../models/Fight";
import { TournamentFight, TournamentRound } from "../../models/Tournament";

// Get fight round name
export const GetFightRoundName = (t: (tag: string, props?: any) => string, fight: Fight, bracket?: TournamentRound[]) => {
  if(bracket && bracket?.length > 0) {
    // Get total of rounds (last position is the Winner, that doesn't count)
    const totalRounds = bracket[bracket.length - 2]?.round || 0;

    // Get round of the current fight
    let fightRound = 0;
    bracket.forEach((round: TournamentRound) => {
      if(round.fights?.length > 0) {
        round.fights.forEach((roundFight: TournamentFight) => {
          if(roundFight.fight?._id === fight._id) fightRound = round.round;
        });
      }
    });

    if(fightRound === totalRounds) return t('FINAL');
    if(fightRound === totalRounds - 1) return t('SEMI_FINALS');
    if(fightRound === totalRounds - 2) return t('QUARTER_FINALS');

    let j = 1;
    for(let i = totalRounds; i !== fightRound; i--) {
      j *= 2;
    }
    
    return `${j}th-${t('FINALS')}`;
  }

  return '';
};

// Get round name
export const GetRoundName = (t: (tag: string, props?: any) => string, round: number, bracket?: TournamentRound[]) => {
  if(bracket && bracket?.length > 0) {
    // Get total of rounds
    const totalRounds = bracket[bracket.length - 1]?.round || 0;

    if(round === totalRounds) return t('WINNER');
    if(round === totalRounds - 1) return t('FINAL');
    if(round === totalRounds - 2) return t('SEMI_FINALS');
    if(round === totalRounds - 3) return t('QUARTER_FINALS');

    let j = 1;
    for(let i = totalRounds - 1; i !== round; i--) {
      j *= 2;
    }
    
    return `${j}th-${t('FINALS')}`;
  }

  return '';
};

// Get round name from tournament
export const GetRoundNameTournament = (t: (tag: string, props?: any) => string, fight: Fight, tournament: any) => {
  if(!!fight && !!tournament.fights && tournament.fights?.length > 0) {
    const fightInTournament = tournament.fights.find((elem: any)  => elem.fight?._id === fight._id);

    if(!!fightInTournament) {
      const totalRounds = tournament.fights[tournament.fights.length - 1].round;

      if(fightInTournament.round === totalRounds) return t('FINAL');
      if(fightInTournament.round === totalRounds - 1) return t('SEMI_FINALS');
      if(fightInTournament.round === totalRounds - 2) return t('QUARTER_FINALS');

      let j = 1;
      for(let i = totalRounds; i !== fightInTournament.round; i--) {
        j *= 2;
      }
      
      return `${j}th-${t('FINALS')}`;
    }
  }

  return '';
};

// True if there's at least one fight with a Winner, False otherwise
export const HasResults = (bracket?: TournamentRound[]) => {
  let result = false;

  if(bracket && bracket?.length > 0) {
    bracket.forEach((round: TournamentRound) => {
      if(round.fights?.length > 0) {
        round.fights.forEach((fight: TournamentFight) => {
          if(fight.fight?.result?.winner) result = true;
        });
      }
    });
  }

  return result;
};

// Check if we can edit a fighter in a specific fight or not
export const CanEditFighter = (bracket: TournamentRound[] | undefined, fight: TournamentFight, type: 'red_fighter' | 'blue_fighter') => {
  let allowEdit = false;

  // In round 1 we can select fighters to any fight
  if(fight.round === 1) allowEdit = true;

  // In round 2 we can only select fighters in a fight that succeeds a Bye
  if(fight.round === 2 && fight.last_games && fight.last_games?.length > 0) {
    const lastRoundFights = bracket?.find(elem => elem.round === (fight.round - 1))?.fights || [];
    const lastFightNumber = type === 'red_fighter' ? fight.last_games[0] : fight.last_games[1];
    const lastFight = lastRoundFights.find(elem => elem.bracket === lastFightNumber);

    if(lastFight?.bye) allowEdit = true;
  }

  return allowEdit;
};

// Check if a fighter already has a Fight or not
export const HasFight = (athlete: string, bracket: TournamentRound[] | undefined) => {
  let result = false;

  if(bracket && bracket?.length > 0) {
    bracket.forEach((round: TournamentRound) => {
      if(round.fights?.length > 0) {
        round.fights.forEach((fight: TournamentFight) => {
          if(fight.fight?.red_fighter?._id === athlete || fight.fight?.blue_fighter?._id === athlete) result = true;
        });
      }
    });
  }

  return result;
};

export const HasFightTournament = (athlete: string, fights: TournamentFight[] | undefined) => {
  let result = false;

  if(fights && fights?.length > 0) {
    fights.forEach((fight: TournamentFight) => {
      if(fight.fight?.red_fighter?._id === athlete || fight.fight?.blue_fighter?._id === athlete) result = true;
    });
  }

  return result;
};

export const DraftRandomFighter = (fighters: { _id: string; drafted: boolean; }[]) => {
  let fighter = '';

  // Filter by undrafted fighters
  const undrafted = fighters.filter((elem: { _id: string; drafted: boolean; }) => !elem.drafted) || [];

  if(undrafted.length > 0) {
    // Select random fighter
    const index = Math.floor(Math.random() * undrafted.length);
    fighter = undrafted[index]._id;
    
    // Mark the fighter as drafted in the original array
    const originalIndex = fighters.findIndex((elem: { _id: string; drafted: boolean; }) => elem._id === fighter);
    fighters[originalIndex].drafted = true;
  }

  return fighter;
};

// Shows a text "Winner of Fight #X" instead of just "TBA" - from BRACKET
export const GetWinnerOf = (t: (tag: string, props?: any) => string, fight: string, bracket: TournamentRound[] | undefined, type: 'red' | 'blue') => {
  if(bracket && bracket.length > 0) {
    const fights: TournamentFight[] = [];
    bracket.forEach((round: TournamentRound) => {
      if(round.fights.length > 0) {
        round.fights.forEach((roundFight: TournamentFight) => {
          if(!!roundFight.fight) fights.push(roundFight);
        });
      }
    });

    return GetWinnerOfAux(t, fight, fights, type);
  }

  return '';
};

// Shows a text "Winner of Fight #X" instead of just "TBA" - from TOURNAMENT
export const GetWinnerOfFromTournament = (t: (tag: string, props?: any) => string, fight: string, tournament: any, type: 'red' | 'blue') => {
  if(tournament?.fights?.length > 0) return GetWinnerOfAux(t, fight, tournament.fights, type);
  return '';
};

// Shows a text "Winner of Fight #X" instead of just "TBA" - auxiliary function
export const GetWinnerOfAux = (t: (tag: string, props?: any) => string, fight: string, fights: TournamentFight[], type: 'red' | 'blue') => {
  if(fights?.length > 0) {
    const roundFight = fights.find((roundFight: TournamentFight) => roundFight.fight?._id === fight);

    if(roundFight?.last_games && roundFight.last_games.length > 0) {
      let lastFightBraket = 0;
      if(type === 'red') lastFightBraket = roundFight.last_games[0];
      else if(type === 'blue') lastFightBraket = roundFight.last_games[1];

      if(lastFightBraket > 0) {
        const lastFight = fights.find((roundFight: TournamentFight) => roundFight.bracket === lastFightBraket);

        if(!!lastFight && !!lastFight.fight) return `${t('WINNER_OF_FIGHT')} #${lastFight.fight?.fight_number}`;
      }
    }
  }
};