import { useTranslation } from 'react-i18next';
import { FC, useContext } from 'react';
import { PROMOTER, User } from '../../../../../models/User';
import { AnyObject } from '../../../../../models/Generic';
import { UserContext } from '../../../../../Context';
import { addItem } from '../../../../../utils/user';
import Typography from '../../../../../components/typography';
import InfoCard, { ListItem } from '../../../common/InfoCard';
import dayjs from 'dayjs';

interface Params {
  athlete?: User;
  isDraftMember: boolean;
}

const InfoCards: FC<Params> = ({ athlete, isDraftMember }) => {
  const { t, i18n: { language } } = useTranslation();
  const { user } = useContext(UserContext);

  let martialArts = '';
  if(athlete?.martial_arts && athlete.martial_arts.length > 0) {
    athlete.martial_arts.forEach((martialArt: AnyObject) => martialArts = `${martialArts}${martialArt.name}, `);

    martialArts = martialArts.slice(0, -2); 
  }

  let evaluationClasses = '';
  if(athlete?.class && athlete.class.length > 0) {
    athlete.class.forEach((elem: AnyObject) => evaluationClasses = `${evaluationClasses}${elem.name[language]}, `);

    evaluationClasses = evaluationClasses.slice(0, -2); 
  }

  const generalItems: ListItem[] = [];
  addItem(generalItems, t('BIRTH_DATE'), athlete?.birthday ? dayjs(athlete?.birthday).format('DD MMM, YYYY') : '');
  addItem(generalItems, t('COUNTRY'), athlete?.country?.name);
  addItem(generalItems, t('GENDER'), t(athlete?.gender || ''));
  addItem(generalItems, t('NATIONALITY'), athlete?.nationality);

  const careerItems: ListItem[] = [];
  addItem(careerItems, t('CLASS'), evaluationClasses);
  addItem(careerItems, t('MARTIAL_ARTS_BACKGROUND'), martialArts);
  addItem(careerItems, t('WEIGHT_CLASS'), athlete?.weight_class?.name && athlete.weight_class.name[language]);
  addItem(careerItems, t('CLUB'), athlete?.club?.name);
  addItem(careerItems, t('RECORD'), athlete?.record);
  addItem(careerItems, t('PROFILE_RECORD_LINK'), athlete?.profile_link, -1, true);

  const federationItems: ListItem[] = [];
  let address = '', guardianAddress = '';

  // Promoters can't view certain fields
  if(user?.type !== PROMOTER) {
    if(athlete?.address) address = athlete.address;
    if(athlete?.postal_code) address = address ? `${address} ${athlete.postal_code}` : athlete.postal_code;
    if(athlete?.city) address = address ? `${address}, ${athlete.city}` : athlete.city;

    if(!!athlete?.guardian_name) {
      if(athlete?.guardian_address) guardianAddress = athlete.guardian_address;
      if(athlete?.guardian_postal_code) guardianAddress = guardianAddress ? `${guardianAddress} ${athlete.guardian_postal_code}` : athlete.guardian_postal_code;
      if(athlete?.guardian_city) guardianAddress = guardianAddress ? `${guardianAddress}, ${athlete.guardian_city}` : athlete.guardian_city;
    }

    addItem(generalItems, t('ADDRESS'), address, 1);
    addItem(generalItems, t('EMAIL'), athlete?.email, 5);
    addItem(generalItems, t('PHONE_NUMBER'), athlete?.phone, 6);
    addItem(generalItems, t('WEIGHT'), athlete?.weight && `${athlete.weight} kg`, 7);
    addItem(generalItems, t('HEIGHT'), athlete?.height && `${athlete.height} cm`, 8);

    addItem(careerItems, t('COACH'), athlete?.coach?.name, 4);

    addItem(federationItems, t('FEDERATION'), athlete?.federation?.name, 0);
  }

  if(!isDraftMember) {
    addItem(federationItems, t('AFFILIATION_NUMBER'), athlete?.associate);
  }

  const guardianItems: ListItem[] = [];
  addItem(guardianItems, t('GUARDIAN_NAME'), athlete?.guardian_name);
  addItem(guardianItems, t('GUARDIAN_BIRTH_DATE'), athlete?.guardian_birthday ? dayjs(athlete?.guardian_birthday).format('DD MMM, YYYY') : '');
  addItem(guardianItems, t('GUARDIAN_PHONE_NUMBER'), athlete?.guardian_phone);
  addItem(guardianItems, t('GUARDIAN_ADDRESS'), guardianAddress);

  // Mark last items as "last" to hide the border
  if(generalItems.length > 0) generalItems[generalItems.length - 1] = { ...generalItems[generalItems.length - 1], last: true };
  if(guardianItems.length > 0) guardianItems[guardianItems.length - 1] = { ...guardianItems[guardianItems.length - 1], last: true };
  if(careerItems.length > 0) careerItems[careerItems.length - 1] = { ...careerItems[careerItems.length - 1], last: true };
  if(federationItems.length > 0) federationItems[federationItems.length - 1] = { ...federationItems[federationItems.length - 1], last: true };
  
  return (
    <>
      {
        generalItems.length > 0 &&
        <InfoCard items={generalItems} />
      }
      {
        user?.type !== PROMOTER && !!athlete?.guardian_name && guardianItems.length > 0 &&
        <>
          <Typography variant='body-small' color='#ffffff' pb={0.688} pt={1.5}>{t('GUARDIAN_INFO').toUpperCase()}</Typography>
          <InfoCard 
            items={guardianItems}
          />
        </>
      }
      {
        careerItems.length > 0 &&
        <>
          <Typography variant='body-small' color='#ffffff' pb={0.688} pt={1.5}>{t('CAREER_INFO').toUpperCase()}</Typography>
          <InfoCard 
            items={careerItems}
          />
        </>
      }
      {
        federationItems.length > 0 &&
        <>
          <Typography variant='body-small' color='#ffffff' pb={0.688} pt={1.5}>{t('FEDERATION_INFO').toUpperCase()}</Typography>
          <InfoCard items={federationItems} />
        </>
      }
    </>
  );
};

export default InfoCards;
