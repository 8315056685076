import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Box } from '../../../styles/BasicStyles';
import { AlertErrorBg } from '../../../styles/Colors';
import { LayoutWrapper } from '../../../components/layout/LayoutStyles';
import { useParams } from 'react-router';
import { DynamicPage } from '../../../models/DynamicPage';
import { ContentWrapper } from '../Styles';
import LoadingOverlay from '../../../components/loadingOverlay';
import useFetch from 'use-http';
import Icon from '../../../components/icon';
import Typography from '../../../components/typography';
import HeaderExternal from '../../../components/header/HeaderExternal';

const DynamicPageComponent = () => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<DynamicPage>();
  const { t, i18n: { language } } = useTranslation();
  const { tag } = useParams();
  const { get } = useFetch('/dynamic-pages');

  useEffect(() => {
    const init = async () => {
      if(tag) {
        //Format tag to transform url friendly to saved tag in database
        const newTag = tag.toUpperCase().replace(/-/g, '_');

        const { data, success } = await get(`/tag/${newTag}`);
        if(success) setInitialValues(data);
      }

      setLoading(false);
    };

    init();
  }, []);

  return (
    <>
      <LoadingOverlay visible={loading} />
      <HeaderExternal />
      <LayoutWrapper showSidebar={false}>
        <Box fDirection='column' maxW={43.75} margin='auto'>
          {
            initialValues?._id ?
            <>
              <ContentWrapper>
                <Typography 
                  variant='body' 
                  color='#ffffff' 
                  dangerouslySetInnerHTML={{
                    __html: initialValues.content[language] || ''
                  }}
                />
              </ContentWrapper>
            </>
            :
            <>
              <Box fDirection='column' fAlign='center' bRadius={0.5} bgColor='#222429' padding='2 1'>
                <Icon icon='outlined_xCircle' size={4} color={AlertErrorBg} />
                <Typography variant='body' fWeight={500} color='#ffffff' pt={1} tAlign='center'>{t('ERROR')}!</Typography>
                <Typography variant='body-small' color='#ffffff' pt={0.5} maxW={25} tAlign='center'>{t('INVALID_FORM_URL')}</Typography>
              </Box>
            </>
          }
        </Box>
      </LayoutWrapper>
    </>
  );
};

export default DynamicPageComponent;
