import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { User } from '../../../../../models/User';
import { addItem } from '../../../../../utils/user';
import Typography from '../../../../../components/typography';
import InfoCard, { ListItem } from '../../../common/InfoCard';
import dayjs from 'dayjs';

interface Params {
  promoter?: User;
  isDraftMember: boolean;
}

const InfoCards: FC<Params> = ({ promoter, isDraftMember }) => {
  const { t } = useTranslation();

  let address = '';
  if(promoter?.address) address = promoter.address;
  if(promoter?.postal_code) address = address ? `${address} ${promoter.postal_code}` : promoter.postal_code;
  if(promoter?.city) address = address ? `${address}, ${promoter.city}` : promoter.city;
  
  const generalItems: ListItem[] = [];
  addItem(generalItems, t('PROMOTER_EMAIL'), promoter?.email);
  addItem(generalItems, t('PROMOTER_PHONE_NUMBER'), promoter?.phone);
  addItem(generalItems, t('PROMOTER_RESPONSABLE_NAME'), promoter?.responsible_name);
  addItem(generalItems, t('PROMOTER_RESPONSABLE_EMAIL'), promoter?.responsible_email);
  addItem(generalItems, t('PROMOTER_RESPONSABLE_CONTACT'), promoter?.responsible_phone);
  addItem(generalItems, t('PROMOTER_CREATION_DATE'), promoter?.promoter_creation ? dayjs(promoter?.promoter_creation).format('DD MMM, YYYY') : '');
  addItem(generalItems, t('TAX_NUMBER'), promoter?.nif);
  addItem(generalItems, t('ADDRESS'), address);
  addItem(generalItems, t('COUNTRY'), promoter?.country?.name);
  
  const federationItems: ListItem[] = [];
  addItem(federationItems, t('FEDERATION'), promoter?.federation?.name);

  if(!isDraftMember) {
    addItem(federationItems, t('AFFILIATION_NUMBER'), promoter?.associate);
  }

  // Mark last items as "last" to hide the border
  if(generalItems.length > 0) generalItems[generalItems.length - 1] = { ...generalItems[generalItems.length - 1], last: true };
  if(federationItems.length > 0) federationItems[federationItems.length - 1] = { ...federationItems[federationItems.length - 1], last: true };

  return (
    <>
      {
        generalItems.length > 0 &&
        <InfoCard 
          items={generalItems}
        />
      }
      {
        federationItems.length > 0 &&
        <>
          <Typography variant='body-small' color='#ffffff' pb={0.688} pt={1.5}>{t('FEDERATION_INFO').toUpperCase()}</Typography>
          <InfoCard 
            items={federationItems}
          />
        </>
      }
    </>
  );
};

export default InfoCards;
