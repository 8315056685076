/* eslint-disable @typescript-eslint/no-explicit-any */
import { User, SUPER_ADMIN, ADMIN, REGULATOR, ATHLETE, OFFICIAL, COACH, UserStatusList, PROMOTER, CLUB } from '../../models/User';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext } from 'react';
import { LabelColor } from '../../styles/Colors';
import { showDelete } from '../../hooks/show-notification/show-notification';
import { Federation } from '../../models/Federation';
import { UserContext } from '../../Context';
import { GetAthleteInitialValues, GetClubInitialValues, GetCoachInitialValues, GetOfficialInitialValues, GetPromoterInitialValues } from '../../utils/modalValues';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import DefaultListPage from '../../components/page/DefaultListPage';
import Icon from '../../components/icon';
import Typography from '../../components/typography';
import DropdownMenu, { ActionProps } from '../../components/dropdownMenu';
import BaseTable from '../../components/table';
import MemberDraftsFilters from './MemberPendingFilters';
import UserCard from '../../components/userCard';
import LabelDropdown from '../../components/labelDropdown';
import ManageCoachModal from '../members/coaches/form';
import ManageAthleteModal from '../members/athletes/form';
import ManageOfficialModal from '../members/officials/form';
import ManagePromoterModal from '../members/promoters/form';
import ManageClubModal from '../members/clubs/form';

const MemberPendingListPage = DefaultListPage<User>();
const MemberPendingTable = BaseTable<User>();

const MemberPendingPage = () => {
  const [federations, setFederations] = useState<Federation[]>([]);
  const [athleteModal, setAthleteModal] = useState<{ open: boolean; initialValues?: User; validateButton?: boolean; rejectButton?: boolean; }>({ open: false });
  const [officialModal, setOfficialModal] = useState<{ open: boolean; initialValues?: User; validateButton?: boolean; rejectButton?: boolean; }>({ open: false });
  const [coachModal, setCoachModal] = useState<{ open: boolean; initialValues?: User; validateButton?: boolean; rejectButton?: boolean; }>({ open: false });
  const [promoterModal, setPromoterModal] = useState<{ open: boolean; initialValues?: User; validateButton?: boolean; rejectButton?: boolean; }>({ open: false });
  const [clubModal, setClubModal] = useState<{ open: boolean; initialValues?: User; validateButton?: boolean; rejectButton?: boolean; }>({ open: false });
  const { t } = useTranslation();
  const membersHook = useFetch('/members');
  const { get } = useFetch('/members/pending');
  const federationsHook = useFetch('/federations');
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      if(user?.type === SUPER_ADMIN || user?.type === ADMIN || user?.type === REGULATOR) {
        const federationsResult = await federationsHook.get('/dropdown');
        setFederations(federationsResult?.data || []);
      }
    };

    init();
  }, []);

  return (
    <MemberPendingListPage
      translate={t}
      request={{
        get: get,
        searchFields: ['name', 'email', 'associate']
      }}
      storage='MEMBER_PENDING_FILTERS'
      header={{ title: t('MEMBER_DRAFTS') }}
      filters={({ filters, handleChange }) => 
        <MemberDraftsFilters 
          filters={filters} 
          handleChange={handleChange}
          federations={federations} 
        />
      }
      countLabel={`${t('MEMBERS').toLowerCase()}`}
    >
      {({ rows, loading, pagination, handleNewSort, refreshPage }) => {
        const handleCloseModal = (changed: boolean) => {
          if (changed) refreshPage();
          setAthleteModal({ open: false });
          setOfficialModal({ open: false });
          setCoachModal({ open: false });
          setPromoterModal({ open: false });
          setClubModal({ open: false });
        };

        const handleDelete = async (id: string) => {
          showDelete({
            title: t('DELETE_MEMBER'),
            message: t('DELETE_MEMBER_MESSAGE'),
            onConfirm: async () => {
              await membersHook.del(`/${id}`);
              refreshPage();
            }
          });
        };

        const onConfirmStatusToogle = async (id: string, status: string) => {
          const { success } = await membersHook.put(`/${id}/change-status`, { status });
          if(success) refreshPage();
        };

        const columns = [
          {
            title: t('ASSOCIATE_NUMBER'),
            dataIndex: 'associate',
            sortable: true
          },
          { 
            title: t('NAME'), 
            dataIndex: 'name', 
            sortable: true,
            render: (_: string, row: User) => <UserCard user={row} showEmail noStatus />
          },
          {
            title: t('TYPE'),
            dataIndex: 'type',
            sortable: true,
            render: (value: string) => <Typography variant='table-header'>{t(value)}</Typography>
          },
          {
            title: t('COUNTRY'),
            dataIndex: 'country',
            sortable: true,
            render: (_: any, row: User) => <Typography variant='table-header'>{row.country?.name}</Typography>
          },
          {
            title: t('STATUS'),
            dataIndex: 'status',
            disableRowClick: true,
            render: (value: string, row: User) => 
              <LabelDropdown 
                status={value} 
                list={UserStatusList} 
                id={row._id} 
                onConfirmStatusToogle={onConfirmStatusToogle}
                disabled={user?.type === CLUB}
              />
          },
          { 
            title: t('MORE'),
            key: 'actions', 
            disableRowClick: true,
            render: (row: User) => {
              let options: ActionProps[] = [
                {
                  type: 'link',
                  label: t('EDIT'),
                  onClick: () => 
                    row.type === ATHLETE ?
                    setAthleteModal({ open: true, initialValues: GetAthleteInitialValues(row) })
                    :
                    row.type === OFFICIAL ?
                    setOfficialModal({ open: true, initialValues: GetOfficialInitialValues(row) })
                    :
                    row.type === COACH ?
                    setCoachModal({ open: true, initialValues: GetCoachInitialValues(row) })
                    :
                    row.type === PROMOTER ?
                    setPromoterModal({ open: true, initialValues: GetPromoterInitialValues(row) })
                    :
                    row.type === CLUB ?
                    setClubModal({ open: true, initialValues: GetClubInitialValues(row) })
                    :
                    null
                },
                {
                  type: 'divider'
                },
                {
                  type: 'link',
                  label: t('DELETE'),
                  onClick: () => handleDelete(row._id)
                }
              ];

              // If the current User is a Club or Coach, and they are seeing their Athletes list, they can only edit their profile if the Athlete allowed it
              if((user?.type === CLUB || user?.type === COACH) && row?.type === ATHLETE && !row?.allow_manage) {
                options = options.slice(2);
              }

              return (
                <DropdownMenu 
                  render={<Icon icon='outlined_dotsHorizontal' color={LabelColor} />}
                  menuPosition='bottom-end'
                  actions={options} 
                />
              );
            }
          }
        ];

        // Show Federation column for Admin users
        if(user?.type === SUPER_ADMIN || user?.type === ADMIN || user?.type === REGULATOR) {
          columns.splice(3, 0, {
            title: t('FEDERATION'),
            dataIndex: 'federation',
            sortable: true,
            render: (_: any, user: User) => <Typography variant='table-header'>{user.federation?.name}</Typography>
          });
        }

        return (
          <>
            <MemberPendingTable
              columns={columns}
              rows={rows}
              loading={loading}
              defaultSort={pagination.sort}
              sortCallback={handleNewSort}
              onRowClick={(row) => navigate(`/members/${row.type?.toLowerCase()}/${row._id}`)}
            />
            <ManageAthleteModal
              opened={athleteModal.open}
              initialValues={athleteModal.initialValues}
              onClose={handleCloseModal}
            />
            <ManageOfficialModal
              opened={officialModal.open}
              initialValues={officialModal.initialValues}
              onClose={handleCloseModal}
            />
            <ManageCoachModal
              opened={coachModal.open}
              initialValues={coachModal.initialValues}
              onClose={handleCloseModal}
            />
            <ManagePromoterModal
              opened={promoterModal.open}
              initialValues={promoterModal.initialValues}
              onClose={handleCloseModal}
            />
            <ManageClubModal
              opened={clubModal.open}
              initialValues={clubModal.initialValues}
              onClose={handleCloseModal}
            />
          </>
        );
      }}
    </MemberPendingListPage>
  );
};

export default MemberPendingPage;
