/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { FC, ReactNode, useContext, useState, useEffect } from 'react';
import { Box, Block } from '../../../styles/BasicStyles';
import { PlaceholderColor } from '../../../styles/Colors';
import { AnyObject } from '../../../models/Generic';
import { InputWrapper } from '../Styles';
import { Field } from 'react-final-form';
import { InfoContext } from '../../../Context';
import { Grid } from '@mantine/core';
import { AspectRatios } from '../../../utils/files/Conversions';
import { CustomField, FieldCheckboxType } from '../../../models/CustomField';
import { Federation, FederationCustomField } from '../../../models/Federation';
import { User } from '../../../models/User';
import Typography from '../../../components/typography';
import FileInput from '../../../components/inputs/FileInput';
import ImageInput from '../../../components/inputs/ImageInput';
import MultiSelectInput from '../../../components/inputs/MultiSelectInput';
import PhoneInput from '../../../components/inputs/PhoneInput';
import CheckboxInput from '../../../components/inputs/CheckboxInput';
import TextInput from '../../../components/inputs/TextInput';
import DateInput from '../../../components/inputs/DateInput';
import SelectInput from '../../../components/inputs/SelectInput';
import dayjs from 'dayjs';

interface FormFieldParams {
  title: string;
  required?: boolean;
  children: ReactNode;
  fullWidth?: boolean;
  translate: (tag: string, props?: any) => string;
}

interface FormInputParams {
  title: string;
  description?: string;
  required?: boolean;
  children: ReactNode;
  translate: (tag: string, props?: any) => string;
}

interface FormFieldsParams {
  handleAcceptedFiles: (inputName: string | undefined, files: AnyObject[]) => void;
  renderInputFiles: (inputName: string) => void;
  customFields?: CustomField[];
  federationInfo?: Federation;
  clubs?: User[];
  initialValues?: User;
  fixedClub?: string;
}

const FormField: FC<FormFieldParams> = ({ title, required = false, children, fullWidth = false, translate }) => {
  return (
    <InputWrapper>
      <Box fAlign='center' fWrap='wrap' pb={0.875}>
        <Typography 
          variant='body' 
          lHeight={1.375} 
          color='#ffffff' 
          pr={{ xs: 0.5, xxs: 0 }}
          w={{ xs: '70%', xxs: '100%' }}
          display='flex'
          order={{ xs: 1, xxs: 2 }}
        >
          {title}
        </Typography>
        {
          required && 
          <Typography 
            variant='body-small' 
            style={{ color: PlaceholderColor }}
            w={{ xs: '30%', xxs: '100%' }}
            display='flex'
            fJustify='flex-end'
            order={{ xs: 2, xxs: 1 }}
            pb={{ xs: 0, xxs: 0.5 }}
          >
            {`* ${translate('REQUIRED_FIELD')}`}
          </Typography>
        }
      </Box>
      <Block w={{ sm: fullWidth ? '100%' : '50%', xxs: '100%' }}>
        {children}
      </Block>
    </InputWrapper>
  );
};

const FormInput: FC<FormInputParams> = ({ title, description, required = false, children, translate }) => {
  return (
    <Box mb={1} fDirection='column'>
      <Box fAlign='center' fWrap='wrap' pb={0.625}>
        <Typography 
          variant='body' 
          lHeight={1.375} 
          color='#ffffff' 
          pr={{ xs: 0.5, xxs: 0 }}
          w={{ xs: '70%', xxs: '100%' }}
          display='flex'
          order={{ xs: 1, xxs: 2 }}
        >
          {title}
        </Typography>
        {
          required && 
          <Typography 
            variant='body-small' 
            style={{ color: PlaceholderColor }}
            w={{ xs: '30%', xxs: '100%' }}
            display='flex'
            fJustify='flex-end'
            order={{ xs: 2, xxs: 1 }}
            pb={{ xs: 0, xxs: 0.5 }}
          >
            {`* ${translate('REQUIRED_FIELD')}`}
          </Typography>
        }
      </Box>
      {
        description &&
        <Typography 
          variant='body-small' 
          style={{ color: PlaceholderColor }}
          pb={0.625}
        >
          {description}
        </Typography>
      }
      <Block w='100%' bRadius={0.5} bgColor='#222429'>
        {children}
      </Block>
    </Box>
  );
};

const FormFields: FC<FormFieldsParams> = ({ handleAcceptedFiles, renderInputFiles, customFields, federationInfo, initialValues, fixedClub, ...props }) => {
  const [dependentFields, setDependentFields] = useState<AnyObject>({});
  const { info } = useContext(InfoContext);
  const { t, i18n: { language } } = useTranslation();

  useEffect(() => {
    if(customFields && customFields.length > 0) {
      const aux: AnyObject = {};

      customFields.filter((elem: CustomField) => !!elem.showOnChange).forEach((elem: CustomField) => {
        if(elem.showOnChange) aux[elem.showOnChange] = false;
      });

      setDependentFields(aux);

      if(initialValues?.birthday) afterDateChange(initialValues.birthday, 'checkBirthday');
    }
  }, [customFields, initialValues]);

  const renderSelect = (customField: CustomField, federationField?: FederationCustomField) => {
    let data: any = [];
    if(info && customField.infoOptions) data = info[customField.infoOptions];
    else if(props && customField.requestOptions) data = props[customField.requestOptions];

    const fieldProps = {
      placeholder: customField.placeholder && t(customField.placeholder),
      clearable: true,
      data,
      isExternal: true,
      intl: customField.fieldOptionsTranslate === "INTL",
      translate: customField.fieldOptionsTranslate === "TRANSLATE",
      disabled: fixedClub && customField.fieldName === 'club' ? true : false
    };

    return (
      <FormField title={t(customField.name)} required={customField.required || federationField?.required} translate={t}>
        <Field name={customField.fieldName}>
          {(props) => (
            <>
              {
                customField.fieldType === 'SELECT' ?
                <SelectInput {...props} {...fieldProps} />
                :
                <MultiSelectInput {...props} {...fieldProps} />
              }
            </>
          )}
        </Field>
      </FormField>
    );
  };

  const afterDateChange = (value?: Date, customFieldChange?: string) => {
    // If the Athlete is under 18, show Guardian fields
    if(customFieldChange === 'checkBirthday') {
      const subtract18 = dayjs().subtract(18, 'year');
      const isAfter = dayjs(value).isAfter(subtract18);

      const aux: AnyObject = {...dependentFields};
      aux[customFieldChange] = isAfter ? true : false;
      setDependentFields(aux);
    }
  };

  const getCategoryDescription = (code?: string) => {
    if(!code) return '';

    const exists = federationInfo?.documents_descriptions?.find((elem: AnyObject) => elem.category.code === code);

    if(exists?.description) return exists.description[language];
  };

  return (
    /* 
    * To show a field we need to have:
    * required and not depending on another field OR
    * required or not and depending on another field, and it passed the validations for that field OR
    * not required and active on the current federation
    */
    <>
      {
        customFields && customFields.length > 0 &&
        customFields.map((customField: CustomField, index: number) => {
          const federationField = federationInfo?.custom_fields?.find((elem: FederationCustomField) => elem.field === customField._id);
          
          return (
            (
              (
                !customField.showOnChange || 
                (customField.showOnChange && dependentFields[customField.showOnChange])
              ) 
              &&
              (
                customField.required ||
                (!!federationField && federationField.active)
              )
            ) &&
            <Block key={index} w='100%'>
              {
                (customField.fieldType === 'TEXT' || customField.fieldType === 'EMAIL') ?
                <FormField title={t(customField.name)} required={customField.required || federationField?.required} translate={t}>
                  <Field name={customField.fieldName}>
                    {(props) => 
                      <TextInput 
                        {...props} 
                        htmlType={customField.fieldType === 'EMAIL' ? 'email' : 'text'}
                        placeholder={customField.placeholder && t(customField.placeholder)}
                        isExternal
                      />
                    }
                  </Field>
                </FormField>
                :
                customField.fieldType === 'PHOTO' ?
                <FormInput title={t(customField.name)} required={customField.required || federationField?.required} translate={t}>
                  <Field name={customField.fieldName}>
                    {(props) =>
                      <ImageInput
                        {...props}
                        ratio={AspectRatios['1:1']}
                        showDropzoneArea
                        borderColor='rgba(255, 255, 255, 0.1)'
                      />
                    }
                  </Field>
                </FormInput>
                :
                customField.fieldType === 'DATE' ?
                <FormField title={t(customField.name)} required={customField.required || federationField?.required} translate={t}>
                  <Field name={customField.fieldName}>
                    {(props) =>
                      <DateInput
                        {...props}
                        placeholder={customField.placeholder && t(customField.placeholder)}
                        isExternal
                        afterChange={(value: Date | undefined) => customField.afterChange && afterDateChange(value, customField.afterChange)}
                      />
                    }
                  </Field>
                </FormField>
                :
                customField.fieldType === 'PHONE' ?
                <FormField title={t(customField.name)} required={customField.required || federationField?.required} translate={t}>
                  <Field name={customField.fieldName}>
                    {(props) => (
                      <PhoneInput
                        {...props}
                        isExternal
                      />
                    )}
                  </Field>
                </FormField>
                :
                (customField.fieldType === 'WEIGHT' || customField.fieldType === 'HEIGHT') ?
                <FormField title={t(customField.name)} required={customField.required || federationField?.required} translate={t}>
                  <Field name={customField.fieldName}>
                    {(props) => (
                      <TextInput 
                        {...props} 
                        placeholder={customField.placeholder && t(customField.placeholder)}
                        htmlType='number'
                        min={0}
                        step={customField.fieldType === 'WEIGHT' ? 0.01 : 0.1}
                        isExternal
                      />
                    )}
                  </Field>
                </FormField>
                :
                (customField.fieldType === 'SELECT' || customField.fieldType === 'MULTI-SELECT') ?
                renderSelect(customField, federationField)
                :
                customField.fieldType === 'FILE' ?
                <>
                  <FormInput 
                    title={t(customField.name)} 
                    description={getCategoryDescription(customField.documentCategoryCode)} 
                    required={customField.required || federationField?.required} 
                    translate={t}
                  >
                    <Field name={customField.fieldName}>
                      {(props) =>
                        <FileInput 
                          {...props}
                          handleAcceptedFiles={handleAcceptedFiles}
                          borderColor='rgba(255, 255, 255, 0.1)'
                          multiple
                        />
                      }
                    </Field>
                  </FormInput>
                  {renderInputFiles(customField.fieldName)}
                </>
                :
                customField.fieldType === 'CHECKBOX' ?
                <FormField title={t(customField.name)} required={customField.required || federationField?.required} translate={t} fullWidth>
                  {
                    customField.fieldCheckboxOptions && customField.fieldCheckboxOptions.length > 0 &&
                    <Grid gutter="xl" style={{ margin: '-0.313rem -0.75rem' }}>
                      {
                        customField.fieldCheckboxOptions.map((option: FieldCheckboxType, optionIndex: number) =>
                          <Grid.Col key={optionIndex} xs={12} sm={6} style={{ padding: '0.313rem 0.75rem' }}>
                            <Field name={option.fieldName}>
                              {props => (
                                <CheckboxInput
                                  {...props}
                                  placeholder={option.placeholder && t(option.placeholder)}
                                />
                              )}
                            </Field>
                          </Grid.Col>
                        )
                      }
                    </Grid>
                  }
                </FormField>
                :
                null
              }
            </Block>
          );
        })
      }
    </>
  );
};

export default FormFields;
